import React from 'react'

function Maps() {
  return (
    <div>
      <div id="location" className="map-location">
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115388.45928299891!2d74.47583742129713!3d25.341493678755352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3968c2368c070a55%3A0xc92f70a42dcb5294!2sBhilwara%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1701419553761!5m2!1sen!2sin" title="ifram2"></iframe> */}

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.465789540492!2d28.628298299999994!3d41.036941999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b55f20ee8b3bc3%3A0xf9ae6b80260a48a8!2sAtat%C3%BCrk%2C%20Adnan%20Menderes%20Cd.%20No%3A8%2C%2034522%20Beylikd%C3%BCz%C3%BC%20Organize%20Sanayi%20B%C3%B6lgesi%2FEsenyurt%2F%C4%B0stanbul!5e0!3m2!1sen!2str!4v1716379510865!5m2!1sen!2str" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>

  )
}

export default Maps
