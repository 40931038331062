import React from "react";
import BreadImg1 from "../../assets/images/breadcrumbs/breadcrumb-01.png";
import BreadImg2 from "../../assets/images/breadcrumbs/breadcrumb-02.png";

function Main({ subtitle, title }) {
  return (
    <div>
      <nav aria-label="breadcrumb">
        <div className="breadcrumb">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-dtls text-center">
                  <h3 className="breadcrumb-title">{title}</h3>
                  <p>
                    {subtitle}
                    <span>{">"} </span> <span> {title}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img src={BreadImg1} className="img-fluid breadcrumb-img-1" alt="" />
          <img src={BreadImg2} className="img-fluid breadcrumb-img-2" alt="" />
        </div>
      </nav>
    </div>
  );
}

export default Main;
