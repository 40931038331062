import React from 'react'
import { Link } from "react-router-dom";
import HomeSliderImg8 from "../../assets/images/slider/slider-08.png";
import HomeSliderImg9 from "../../assets/images/slider/slider-09.png";
import HomeSliderImg10 from "../../assets/images/slider/slider-10.png";
import HomeSliderImg11 from "../../assets/images/slider/slider-11.png";

function Sliderfour() {
    return (
        <div>
            <section id="slider" className="slider-main-block-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <h2 className="slider-heading-4">
                                CHOOSE YOUR NEXT <span>DREAM</span> PLACE.
                            </h2>
                            <p className="slider-txt">
                                embarking on a home search, it's crucial to define your
                                priorities and budget. Utilize online real estate platforms and
                                work with a local realtor to access listings. Don't rush; take
                                your time to visit potential homes
                            </p>
                            <div className="slider-4-btn">
                                <Link
                                    to="/contact_us"
                                    title=""
                                    className="btn pointer-zoom btn-primary contact-btn"
                                >
                                    Contact us
                                </Link>
                                <Link to="#" title="" className="pointer-zoom btn-link">
                                    Learn More
                                </Link>
                                <i className="flaticon-right-arrow-1 pointer-zoom"></i>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="slider-img">
                                <div className="slider-building-img-4">
                                    <img
                                        src={HomeSliderImg8}
                                        className="img-fluid"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="slider-dtls-2">
                                <h6 className="slider-heading-2">Latest Properties</h6>
                                <ul className="slider-lst-4">
                                    <li>
                                        <p className="lst-txt">Location</p>
                                        <button
                                            className="btn pointer-zoom btn-secondary dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Manstrait, UK
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <button className="dropdown-item" type="button">
                                                    Action
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item" type="button">
                                                    Another action
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item" type="button">
                                                    Something else here
                                                </button>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p className="lst-txt">Type</p>
                                        <button
                                            className="btn pointer-zoom btn-secondary dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            apartments
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <button className="dropdown-item" type="button">
                                                    Action
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item" type="button">
                                                    Another action
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item" type="button">
                                                    Something else here
                                                </button>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p className="lst-txt">Price</p>
                                        <button
                                            className="btn pointer-zoom btn-secondary dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            $1,43,000
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <button className="dropdown-item" type="button">
                                                    Action
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item" type="button">
                                                    Another action
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item" type="button">
                                                    Something else here
                                                </button>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <div className="slider-btn-block-2">
                                    <Link
                                        to="#"
                                        title=""
                                        className="btn btn-primary pointer-zoom search-btn"
                                    >
                                        <i className="flaticon-search"></i>Search
                                    </Link>
                                    <div className="slider-img-2">
                                        <div className="row">
                                            <div className="col-lg-3 col-3">
                                                <img
                                                    src={HomeSliderImg9}
                                                    className="img-fluid slider-member-1"
                                                    alt=""
                                                />
                                                <img
                                                    src={HomeSliderImg10}
                                                    className="img-fluid slider-member-2"
                                                    alt=""
                                                />
                                                <img
                                                    src={HomeSliderImg11}
                                                    className="img-fluid slider-member-3"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-lg-9 col-9 text-start">
                                                <p>11,212+ People have found their home</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Sliderfour