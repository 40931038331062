import React, { useState } from 'react'
import { Link } from "react-router-dom";
import VideoBgImg4 from "../../assets/images/bg/video-bg-4.png";

function Vdofour() {

    const [showPlayer, setshowPlayer] = useState(false);

    return (
        <div>
            <section
                id="video"
                className="video-main-block-4 parallax"
                style={{ backgroundImage: `url(${VideoBgImg4})` }}
            >
                <div className="overlay-bg"></div>
                <div className="video-dtls ">
                    <h2 className="video-headings-4">
                        Watch The Full
                        <br />
                        Apartment
                        <br />
                        View
                    </h2>
                </div>
                <div className="play-btn-4">
                    <Link
                        to="#"
                        title=""
                        data-bs-toggle="modal"
                        data-bs-target="#videomodal"
                        onClick={() => setshowPlayer(!showPlayer)}
                    >
                        <i className="flaticon-play"></i>
                    </Link>
                </div>
                <div
                    className={showPlayer ? "modal d-block" : "modal fade"}
                    id="videomodal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    onClick={() => setshowPlayer(!showPlayer)}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <iframe
                                    width="650"
                                    height="400"
                                    title="ifram3"
                                    src="https://www.youtube.com/embed/videoseries?si=VW5WfnzCjbpDnW57&amp;list=PLs9JJVQP1gn2SVodyu-ydHCnfY_ZgMocO"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Vdofour