import React from 'react'
import { Link } from "react-router-dom";
import Slider from "react-slick";
import TeamMemberImg5 from "../../assets/images/teams/member-05.jpg";
import TeamMemberImg6 from "../../assets/images/teams/member-06.jpg";
import TeamMemberImg7 from "../../assets/images/teams/member-07.jpg";
import TeamMemberImg8 from "../../assets/images/teams/member-08.jpg";

const Teamslider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};

function Teamfour() {
    return (
        <div>
            <section id="teams" className="teams-main-block-2 teams-main-block-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section">
                                <h6 className="section-sub-heading-2">
                                    <i className="flaticon-dot dot-icon"></i>Team members
                                </h6>
                                <h3 className="section-heading mb_60">
                                    Meet Our Creative Team Members
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Slider className="team-slider-3" {...Teamslider}>
                            <div className="teams-block-2">
                                <div className="member-img bg-blue">
                                    <Link to="/team_detail" title="">
                                        <img
                                            src={TeamMemberImg5}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="member-dtls-2">
                                    <h6 className="member-name">
                                        <Link to="/team_detail" title="">
                                            Anderson Elizabeth
                                        </Link>
                                    </h6>
                                    <div className="member-position">Agent Supervisor</div>
                                </div>
                                <div className="member-social-2">
                                    <div className="btn-group dropup">
                                        <button
                                            type="button"
                                            className="btn pointer-zoom dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="flaticon-plus"></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-facebook-app-symbol"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-twitter-1"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-linkedin"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-pinterest"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-instagram"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="teams-block-2">
                                <div className="member-img bg-blue">
                                    <Link to="/team_detail" title="">
                                        <img
                                            src={TeamMemberImg6}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="member-dtls-2">
                                    <h6 className="member-name">
                                        <Link to="/team_detail" title="">
                                            Andoles John
                                        </Link>
                                    </h6>
                                    <div className="member-position">Marketing Manager</div>
                                </div>
                                <div className="member-social-2">
                                    <div className="btn-group dropup">
                                        <button
                                            type="button"
                                            className="btn pointer-zoom dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="flaticon-plus"></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-facebook-app-symbol"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-twitter-1"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-linkedin"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-pinterest"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-instagram"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="teams-block-2">
                                <div className="member-img bg-blue">
                                    <Link to="/team_detail" title="">
                                        <img
                                            src={TeamMemberImg7}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="member-dtls-2">
                                    <h6 className="member-name">
                                        <Link to="/team_detail" title="">
                                            Michal Jessi
                                        </Link>
                                    </h6>
                                    <div className="member-position">Project Manager</div>
                                </div>
                                <div className="member-social-2">
                                    <div className="btn-group dropup">
                                        <button
                                            type="button"
                                            className="btn pointer-zoom dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="flaticon-plus"></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-facebook-app-symbol"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-twitter-1"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-linkedin"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-pinterest"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-instagram"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="teams-block-2">
                                <div className="member-img bg-blue">
                                    <Link to="/team_detail" title="">
                                        <img
                                            src={TeamMemberImg8}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="member-dtls-2">
                                    <h6 className="member-name">
                                        <Link to="/team_detail" title="">
                                            Josep Anderson
                                        </Link>
                                    </h6>
                                    <div className="member-position">Financial Manager</div>
                                </div>
                                <div className="member-social-2">
                                    <div className="btn-group dropup">
                                        <button
                                            type="button"
                                            className="btn pointer-zoom dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="flaticon-plus"></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-facebook-app-symbol"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-twitter-1"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-linkedin"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-pinterest"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-instagram"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="teams-block-2">
                                <div className="member-img bg-blue">
                                    <Link to="/team_detail" title="">
                                        <img
                                            src={TeamMemberImg5}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="member-dtls-2">
                                    <h6 className="member-name">
                                        <Link to="/team_detail" title="">
                                            Anderson Elizabeth
                                        </Link>
                                    </h6>
                                    <div className="member-position">Agent Supervisor</div>
                                </div>
                                <div className="member-social-2">
                                    <div className="btn-group dropup">
                                        <button
                                            type="button"
                                            className="btn pointer-zoom dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="flaticon-plus"></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-facebook-app-symbol"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-twitter-1"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-linkedin"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-pinterest"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-instagram"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="teams-block-2">
                                <div className="member-img bg-blue">
                                    <Link to="/team_detail" title="">
                                        <img
                                            src={TeamMemberImg6}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="member-dtls-2">
                                    <h6 className="member-name">
                                        <Link to="/team_detail" title="">
                                            Andoles John
                                        </Link>
                                    </h6>
                                    <div className="member-position">Marketing Manager</div>
                                </div>
                                <div className="member-social-2">
                                    <div className="btn-group dropup">
                                        <button
                                            type="button"
                                            className="btn pointer-zoom dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="flaticon-plus"></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-facebook-app-symbol"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-twitter-1"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-linkedin"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-pinterest"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#">
                                                    <i className="flaticon-instagram"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Teamfour