import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import Propertyimg8 from '../../assets/images/properties/properties-08.png'
import Propertyimg9 from '../../assets/images/properties/properties-09.png'
import Propertyimg10 from '../../assets/images/properties/properties-10.png'

const Propertyfourslider = {
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};



function Propertyfour() {
    return (
        <div>
            <section id="properties" className="properties-main-block-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-12">
                            <div className="section mb_60">
                                <h6 className="section-sub-heading text-start">
                                    <i className="flaticon-dot dot-icon" />
                                    PROPERTIES
                                </h6>
                                <h3 className="section-heading text-start">
                                    explore Our Latest &amp; Featured Properties
                                </h3>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12">
                            <div className="vertical-line text-center" />
                        </div>
                        <div className="col-lg-5">
                            <div className="sub-section-2">
                                <p>
                                    $100 Million for a removed 19th century home in City Park. Renovaled
                                    the interior and build a three-story rear addition that blends the
                                    company reputedly complete all our project.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Slider className="properties-slider-4" {...Propertyfourslider}>
                            <div style={{ padding: '0 10px' }}>
                                <div className="propeties-block-4">
                                    <div className="properties-4-img">
                                        <img src={Propertyimg8} className="img-fluid" alt="" />
                                        <span className="badge sale-bd-2">Urgent Sale</span>
                                    </div>
                                    <div className="properties-details">
                                        <p>
                                            <i className="flaticon-location" />
                                            Estrait Australia, AU
                                        </p>
                                        <div className="properties-heading-block">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8">
                                                    <h5 className="properties-heading">
                                                        <Link to="#" title="">
                                                            Remonda Luxury Apartments
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Link
                                                        to="#"
                                                        className="btn pointer-zoom btn-primary properties-btn"
                                                        title=""
                                                    >
                                                        $79999
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="horizontal-line" />
                                    </div>
                                    <div className="properties-icon-4">
                                        <ul className="properties-icon">
                                            <li className="text-center">
                                                <i className="flaticon-bed-1" />
                                                <p>3 Bed Rooms</p>
                                            </li>
                                            <li>|</li>
                                            <li className="text-center">
                                                <i className="flaticon-bath-tub" />
                                                <p>2 Baths</p>
                                            </li>
                                            <li>|</li>
                                            <li className="text-center">
                                                <i className="flaticon-network" />
                                                <p>1200 sqr</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <Link to="#" className="btn pointer-zoom btn-link" title="">
                                        More Details <i className="flaticon-right-arrow" />
                                    </Link>
                                </div>
                            </div>
                            <div style={{ padding: '0 10px' }}>
                                <div className="propeties-block-4">
                                    <div className="properties-4-img">
                                        <img src={Propertyimg9} className="img-fluid" alt="" />
                                        <span className="badge sale-bd-2">Urgent Sale</span>
                                    </div>
                                    <div className="properties-details">
                                        <p>
                                            <i className="flaticon-location" />
                                            Jean-Talon Est,Montréal, CA
                                        </p>
                                        <div className="properties-heading-block">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8">
                                                    <h5 className="properties-heading">
                                                        <Link to="#" title="">
                                                            Remonda Luxury Apartments
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Link
                                                        to="#"
                                                        className="btn pointer-zoom btn-primary properties-btn"
                                                        title=""
                                                    >
                                                        $79999
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="horizontal-line" />
                                    </div>
                                    <div className="properties-icon-4">
                                        <ul className="properties-icon">
                                            <li className="text-center">
                                                <i className="flaticon-bed-1" />
                                                <p>3 Bed Rooms</p>
                                            </li>
                                            <li>|</li>
                                            <li className="text-center">
                                                <i className="flaticon-bath-tub" />
                                                <p>2 Baths</p>
                                            </li>
                                            <li>|</li>
                                            <li className="text-center">
                                                <i className="flaticon-network" />
                                                <p>1200 sqr</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <Link to="#" className="btn pointer-zoom btn-link" title="">
                                        More Details <i className="flaticon-right-arrow" />
                                    </Link>
                                </div>
                            </div>
                            <div style={{ padding: '0 10px' }}>
                                <div className="propeties-block-4">
                                    <div className="properties-4-img">
                                        <img src={Propertyimg10} className="img-fluid" alt="" />
                                        <span className="badge sale-bd-2">Urgent Sale</span>
                                    </div>
                                    <div className="properties-details">
                                        <p>
                                            <i className="flaticon-location" />
                                            Colombia Street, UK
                                        </p>
                                        <div className="properties-heading-block">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8">
                                                    <h5 className="properties-heading">
                                                        <Link to="#" title="">
                                                            Remonda Luxury Apartments
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Link
                                                        to="#"
                                                        className="btn pointer-zoom btn-primary properties-btn"
                                                        title=""
                                                    >
                                                        $79999
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="horizontal-line" />
                                    </div>
                                    <div className="properties-icon-4">
                                        <ul className="properties-icon">
                                            <li className="text-center">
                                                <i className="flaticon-bed-1" />
                                                <p>3 Bed Rooms</p>
                                            </li>
                                            <li>|</li>
                                            <li className="text-center">
                                                <i className="flaticon-bath-tub" />
                                                <p>2 Baths</p>
                                            </li>
                                            <li>|</li>
                                            <li className="text-center">
                                                <i className="flaticon-network" />
                                                <p>1200 sqr</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <Link to="#" className="btn pointer-zoom btn-link" title="">
                                        More Details <i className="flaticon-right-arrow" />
                                    </Link>
                                </div>
                            </div>
                            <div style={{ padding: '0 10px' }}>
                                <div className="propeties-block-4">
                                    <div className="properties-4-img">
                                        <img src={Propertyimg8} className="img-fluid" alt="" />
                                        <span className="badge sale-bd-2">Urgent Sale</span>
                                    </div>
                                    <div className="properties-details">
                                        <p>
                                            <i className="flaticon-location" />
                                            Estrait Australia, AU
                                        </p>
                                        <div className="properties-heading-block">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8">
                                                    <h5 className="properties-heading">
                                                        <Link to="#" title="">
                                                            Remonda Luxury Apartments
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Link
                                                        to="#"
                                                        className="btn pointer-zoom btn-primary properties-btn"
                                                        title=""
                                                    >
                                                        $79999
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="horizontal-line" />
                                    </div>
                                    <div className="properties-icon-4">
                                        <ul className="properties-icon">
                                            <li className="text-center">
                                                <i className="flaticon-bed-1" />
                                                <p>3 Bed Rooms</p>
                                            </li>
                                            <li>|</li>
                                            <li className="text-center">
                                                <i className="flaticon-bath-tub" />
                                                <p>2 Baths</p>
                                            </li>
                                            <li>|</li>
                                            <li className="text-center">
                                                <i className="flaticon-network" />
                                                <p>1200 sqr</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <Link to="#" className="btn pointer-zoom btn-link" title="">
                                        More Details <i className="flaticon-right-arrow" />
                                    </Link>
                                </div>
                            </div>
                            <div style={{ padding: '0 10px' }}>
                                <div className="propeties-block-4">
                                    <div className="properties-4-img">
                                        <img src={Propertyimg9} className="img-fluid" alt="" />
                                        <span className="badge sale-bd-2">Urgent Sale</span>
                                    </div>
                                    <div className="properties-details">
                                        <p>
                                            <i className="flaticon-location" />
                                            Jean-Talon Est,Montréal, CA
                                        </p>
                                        <div className="properties-heading-block">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8">
                                                    <h5 className="properties-heading">
                                                        <Link to="#" title="">
                                                            Remonda Luxury Apartments
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <Link
                                                        to="#"
                                                        className="btn pointer-zoom btn-primary properties-btn"
                                                        title=""
                                                    >
                                                        $79999
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="horizontal-line" />
                                    </div>
                                    <div className="properties-icon-4">
                                        <ul className="properties-icon">
                                            <li className="text-center">
                                                <i className="flaticon-bed-1" />
                                                <p>3 Bed Rooms</p>
                                            </li>
                                            <li>|</li>
                                            <li className="text-center">
                                                <i className="flaticon-bath-tub" />
                                                <p>2 Baths</p>
                                            </li>
                                            <li>|</li>
                                            <li className="text-center">
                                                <i className="flaticon-network" />
                                                <p>1200 sqr</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <Link to="#" className="btn pointer-zoom btn-link" title="">
                                        More Details <i className="flaticon-right-arrow" />
                                    </Link>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Propertyfour