import React from 'react'
import CountUp from "react-countup";

function Facts() {
    return (
        <div>
            <section id="facts" className="facts-main-block">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="fats-block">
                                <div className="count number">
                                    <CountUp start={0} end={100} duration={3} />
                                </div>
                                <h4 className="facts-text">Team Members</h4>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="fats-block">
                                <div className="count number">
                                    <CountUp start={0} end={1500} duration={3} />
                                </div>
                                <h4 className="facts-text">Project Done</h4>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="fats-block">
                                <div className="count number">
                                    <CountUp start={0} end={879} duration={3} />
                                </div>
                                <h4 className="facts-text">Happy Client</h4>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="fats-block">
                                <div className="count number">
                                    <CountUp start={0} end={120} duration={3} />
                                </div>
                                <h4 className="facts-text">Trusted Agents</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Facts