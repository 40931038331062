import React from 'react'
import { BrowserRouter as Router } from "react-router-dom"
import Routing from './routes'

import './assets/fonts/flaticon/flaticon_rexion.css'
import './assets/css/bootstrap.min.css'
import './vendor/slick-slider/slick.css'
import './assets/css/style.css'

function App() {
  return (
    <div>
      <Router >
        <Routing />
      </Router>
    </div>
  );
}

export default App