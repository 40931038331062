import React from 'react'
import { Link } from 'react-router-dom'

function Contactus() {
    return (
        <div>
            <section id="contact" className="contact-main-block">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section text-center">
                                <h6 className="section-sub-heading">Contact Us</h6>
                                <h3 className="section-heading mb_30">Get in Touch</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-lg-2 col-lg-3 col-md-3">
                            <div className="contact-block pointer-zoom">
                                <i className="flaticon-maps-and-flags"></i>
                                <div className="contact-dtls">Alison Street, 10090, Ca</div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                            <div className="contact-block pointer-zoom">
                                <i className="flaticon-call"></i>
                                <div className="contact-dtls">
                                    <Link to="tel:" title="">
                                        +900 8909804
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-5">
                            <div className="contact-block pointer-zoom">
                                <i className="">
                                    <i className="flaticon-email"></i>
                                </i>
                                <div className="contact-dtls">
                                    <Link to="mailto:" title="">
                                        hellosupport@example.com
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-lg-2 col-lg-8">
                            <div className="contact-form-block">
                                <form>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label htmlFor="name" className="form-label">
                                                Your Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                aria-describedby="name"
                                                placeholder="Full Name"
                                                autoComplete="given-name"
                                            />
                                            <label htmlFor="mail" className="form-label">
                                                Mail
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="mail"
                                                aria-describedby="mail"
                                                placeholder="E-Mail"
                                            />
                                            <label htmlFor="phone" className="form-label">
                                                Phone Number
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="phone"
                                                aria-describedby="phone"
                                                placeholder="Phone Number"
                                                autoComplete="given-name"
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <label htmlFor="message">Message</label>
                                            <textarea
                                                className="form-control"
                                                placeholder="Your Message"
                                                id="message"
                                                rows="13"
                                            ></textarea>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-btn text-center">
                                                <button
                                                    type="submit"
                                                    className="btn pointer-zoom btn-primary"
                                                >
                                                    Submit Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contactus