import React from 'react';
import Abtimg1 from '../../assets/images/about/about-3.png';
import Abtimg2 from '../../assets/images/about/about-03.png';

function Abtfour() {
    const aboutFacts = [
        { icon: 'flaticon-protected', title: 'Payment Security' },
        { icon: 'flaticon-ribbon', title: 'Best Quality' },
        { icon: 'flaticon-influencer', title: 'Expert Support' },
    ];

    return (
        <div>
            <section id="about" className="about-main-block-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="about-img-3">
                                <div className="main-img">
                                    <img src={Abtimg1} className="img-fluid" alt="" />
                                </div>
                                <div className="img-2">
                                    <img src={Abtimg2} className="img-fluid" alt="" />
                                </div>
                                <div className="year">
                                    10+<span>Years of Experience</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="section">
                                <h6 className="section-sub-heading">
                                    <i className="flaticon-dot dot-icon" />
                                    About Us
                                </h6>
                                <h3 className="section-heading mb_30">
                                    Experts forecasts and insights expected trends
                                </h3>
                                <p className="mb_30">
                                    Realco Kızıltan Gayrimenkul Yatırım we understand that real estate
                                    transactions are not just about properties they are about people and
                                    their dreams.
                                </p>
                                <p className="mb_40">
                                    Realco Kızıltan Gayrimenkul Yatırım we understand that real estate
                                    transactions are not just about properties they are about people and
                                    their dreams. That's why we take the time to listen to your unique
                                    needs and aspirations
                                </p>
                            </div>
                            <ul className="about-facts-3">
                                {aboutFacts.map((fact, index) => (
                                    <li key={index}>
                                        <div className="about-fact-icon">
                                            <i className={fact.icon} />
                                        </div>
                                        <div className="about-fact-title">{fact.title}</div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="border-box-2" />
            </section>
        </div>
    );
}

export default Abtfour;
