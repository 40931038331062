import React from 'react'
import { Link } from 'react-router-dom'
import AboutImg2 from "../../assets/images/about/about-02.png";
import AboutVideoImg from "../../assets/images/about/about-video.png";
import AboutUserImg from "../../assets/images/about/about-user.png";
import AboutSignImg from "../../assets/images/about/sign.svg";

function AbtTwo() {
    return (
        <div>
            <section id="about" className="about-main-block-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-img-2">
                                <div className="main-img">
                                    <img src={AboutImg2} className="img-fluid" alt="" />
                                </div>
                                <div className="video-img">
                                    <img src={AboutVideoImg} className="img-fluid" alt="" />
                                </div>
                                <div className="year">
                                    10+
                                    <br />
                                    <span>Years</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section">
                                <h6 className="section-sub-heading">About Us</h6>
                                <h3 className="section-heading">
                                    We are Top Rated Kızıltan Gayrimenkul Yatırım Worldwide
                                </h3>
                                <p className="about-2-txt">
                                    Realco Kızıltan Gayrimenkul Yatırım we understand that real estate
                                    transactions are not just about properties they are about
                                    people and their dreams. That's why we take the time to listen
                                    to your unique needs and aspirations
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="about-features-2">
                                        <div className="row">
                                            <div className="col-lg-3 col-6">
                                                <div className="about-features-icons">
                                                    <i className="flaticon-blueprint"></i>
                                                </div>
                                            </div>
                                            <div className="col-lg-9 col-6">
                                                <h6 className="about-features-title">
                                                    Smart Home Architecture
                                                </h6>
                                                <p>We working with your real estate dreams a reality</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-features-2">
                                        <div className="row">
                                            <div className="col-lg-3 col-6">
                                                <div className="about-features-icons">
                                                    <i className="flaticon-blueprint"></i>
                                                </div>
                                            </div>
                                            <div className="col-lg-9 col-6">
                                                <h6 className="about-features-title">
                                                    Reasonable Pricing Plan
                                                </h6>
                                                <p>We working with your real estate dreams a reality</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about-2-btn">
                                <Link
                                    to="/about"
                                    className="btn pointer-zoom btn-primary"
                                    title=""
                                >
                                    About More
                                </Link>
                                <img
                                    src={AboutUserImg}
                                    className="img-fluid about-user"
                                    alt=""
                                />
                                <img src={AboutSignImg} className="img-fluid sign-img" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AbtTwo