import React from "react";
import HeaderOne from "../HeaderOne/Main";
import Breadcrumb from "../Breadcrumb/Main";
import Abtone from "../Menhomeone/Abtone";
import Serviceblock from "../Menhomefour/Serviceblock";
import FactsThree from "../MenhomeThree/FactsThree";
import Teamtwo from "../MenhomeTwo/Teamtwo";
import Newslatter from "../Menhomeone/Newsletter";
import FooterOne from "../FooterOne/Main";


function Main() {
  return (
    <div>
      <HeaderOne />
      <Breadcrumb subtitle="Anasayfa" title="Hakkımızda" />
      <Abtone />
      {/*<Serviceblock />
      <FactsThree />
      <Teamtwo />
      <Newslatter /> */}
      <FooterOne />
    </div>
  );
}

export default Main;
