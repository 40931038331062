import React from 'react'
import Slider from "react-slick";
import ProjectImg5 from "../../assets/images/projects/projects-05.png";
import ProjectImg6 from "../../assets/images/projects/projects-06.png";

const latestprojectslider = {
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: false,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

function Latestprojectfour() {
    return (
        <div>
            <section id="latest-projects" className="projects-main-block-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-12">
                            <div className="section mb_60">
                                <h6 className="section-sub-heading text-start">
                                    <i className="flaticon-dot dot-icon"></i>Project gallery
                                </h6>
                                <h3 className="section-heading text-start">
                                    Our Latest Project Gallery
                                </h3>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="vertical-line text-center"></div>
                        </div>
                        <div className="col-lg-5 col-12">
                            <div className="sub-section-2">
                                <p>
                                    $100 Million for a removed 19th century home in City Park.
                                    Renovaled the interior and build a three-story rear addition
                                    that blends the company reputedly complete all our project.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Slider className="projects-slider-4" {...latestprojectslider}>
                        <div className="projects-block-4">
                            <div className="project-4-details-1">
                                <h3 className="projects-heading-4">Duplex House</h3>
                                <p className="project-4-txt">Monstrait City, CA</p>
                            </div>
                            <div className="project-4-img">
                                <img
                                    src={ProjectImg5}
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="projects-block-4">
                            <div className="project-4-details-2">
                                <h3 className="projects-heading-4">Apartments</h3>
                                <p className="project-4-txt">Monstrait City, CA</p>
                            </div>
                            <div className="project-4-img">
                                <img
                                    src={ProjectImg6}
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="projects-block-4">
                            <div className="project-4-details-1">
                                <h3 className="projects-heading-4">Duplex House</h3>
                                <p className="project-4-txt">Monstrait City, CA</p>
                            </div>
                            <div className="project-4-img">
                                <img
                                    src={ProjectImg5}
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="projects-block-4">
                            <div className="project-4-details-2">
                                <h3 className="projects-heading-4">Apartments</h3>
                                <p className="project-4-txt">Monstrait City, CA</p>
                            </div>
                            <div className="project-4-img">
                                <img
                                    src={ProjectImg6}
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        </div>
    )
}

export default Latestprojectfour