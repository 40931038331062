import React from "react";
import { Link } from "react-router-dom";
import FooterLogo1 from "../../assets/images/general/footer-logo.png"

function Main() {
  let scrollOnTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <footer className="footer-main-block">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="footer-menu">
                <ul>
                  <li>
                    <Link to="/" title="">
                      Anasayfa
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" title="">
                      Hakkımızda
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/service" title="">
                      Services
                    </Link>
                  </li> 
                  <li>
                    <Link to="/service_details" title="">
                      Property
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog_single" title="">
                      Blog
                    </Link>
                  </li>*/}
                  <li>
                    <Link to="/contact_us" title="">
                      İletişim
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="footer-social text-end">
                <ul>
                  <li>
                    <Link to="https://www.facebook.com/" target="_blank" title="">
                      <i className="flaticon-facebook-app-symbol"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://twitter.com/login" target="_blank" title="">
                      <i className="flaticon-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://in.linkedin.com/" target="_blank" title="">
                      <i className="flaticon-linkedin"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://in.pinterest.com/" target="_blank" title="">
                      <i className="flaticon-pinterest"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/" target="_blank" title="">
                      <i className="flaticon-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="horizontal-line-2"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-contact text-center">
                <h2 className="contact-no">
                  <Link to="tel:" title="">
                    +90 212 886 75 23
                  </Link>
                </h2>
                <h5 className="contact-heding">Merkez Ofis</h5>
                <div className="address">
                  Atatürk Mah. Adnan Menderes Cad. No: 8 Esenyurt 34513<br />
                  İstanbul - Türkiye
                </div>
                <div className="address">
                  info@kiziltangayrimenkul.com.tr
                </div>
                <div className="opening-hrs">
                  Çalışma Saatleri : 08.00 - 17.30
                </div>                                
              </div>
              <div className="toTop">
                <i className="flaticon-up-arrow" onClick={scrollOnTop}></i>
              </div>
            </div>
          </div>
        </div>
        <div className="tiny-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-12 text-start">
                <div className="copyright">
                  Copyright &copy; Kızıltan Gayrimenkul
                </div>
              </div>
              <div className="col-lg-4 col-12 text-center">
                <div className="footer-logo">
                  <Link to="index" title="">
                    <img
                      src={FooterLogo1}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-12 text-end">
                <div className="policy">
                  <Link to="#" title="">
                    Terms &amp; Conditions / Privacy &amp; Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>

  );
}

export default Main;
