import React from "react";
import HeaderOne from "../HeaderOne/Main";
import Breadcrumb from "../Breadcrumb/Main";
import Contact from "../Mencontact/Contact";
import Maps from "../Mencontact/Maps";
import Newsletter from "../Menhomeone/Newsletter";
import FooterOne from "../FooterOne/Main";


function Main() {
  return (
    <div>
      <HeaderOne />
      <Breadcrumb title="İletişim" subtitle="Anasayfa" />
      <Contact />
      <Maps />
      <Newsletter />
      <FooterOne />
    </div>
  );
}

export default Main;
