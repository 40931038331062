import React from "react";
import {Routes, Route } from "react-router-dom";

import Home from "../component/HomeOne/Main";
import HomeTwo from "../component/HomeTwo/Main";
import HomeThree from "../component/HomeThree/Main";
import HomeFour from "../component/HomeFour/Main";
import About from "../component/About/Main";
import Service from "../component/Service/Main";
import ServiceDetails from "../component/ServiceDetails/Main";
import TeamMembers from "../component/TeamMembers/Main";
import TeamDetails from "../component/TeamDetails/Main";
import Blog from "../component/Blog/Main";
import BlogDetails from "../component/BlogDetails/Main";
import ErrorPage from "../component/ErrorPage/Main";
import ContactUs from "../component/Contact/Main";
import OnScroll from "../component/Utils/OnScroll";

function index() {
  return (
    <>
        <OnScroll />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/hometwo" element={<HomeTwo />} />
        <Route path="/homethree" element={<HomeThree />} />
        <Route path="/homefour" element={<HomeFour />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service_details" element={<ServiceDetails />} />
        <Route path="/team_members" element={<TeamMembers />} />
        <Route path="/team_detail" element={<TeamDetails />} />
        <Route path="/blog_single" element={<Blog />} />
        <Route path="/blog_details" element={<BlogDetails />} />
        <Route path="/error_page" element={<ErrorPage />} />
        <Route path="/contact_us" element={<ContactUs />} />
      </Routes>
    </>
  );
}

export default index;
