import React from 'react';
import { Link } from 'react-router-dom';

function Error() {
  return (
    <div>
      <section id="error" className="error-main-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="error-heading-1">
                <h1 className="error-num">
                  4<span>0</span>4
                </h1>
              </div>
              <div className="error-dtls">
                <h3 className="error-heading-2">OOPS! Nothing Was Found</h3>
                <p>
                  Oops! it could be you or us, there is no page here. It might
                  <br />
                  have been moved or deleted.Back To Home
                </p>
                <Link
                  to="#"
                  className="btn pointer-zoom btn-primary error-btn"
                  title=""
                >
                  Go Back To Home <i className="flaticon-right-arrow"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

export default Error
