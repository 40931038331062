import React from 'react';
import Slider from 'react-slick';
import HomePatnerImg1 from '../../assets/images/partners/partners-01.png';
import HomePatnerImg2 from '../../assets/images/partners/partners-02.png';
import HomePatnerImg3 from '../../assets/images/partners/partners-03.png';
import HomePatnerImg4 from '../../assets/images/partners/partners-04.png';
import HomePatnerImg5 from '../../assets/images/partners/partners-05.png';
import HomePatnerImg6 from '../../assets/images/partners/partners-06.png';

const partnerImages = [
    HomePatnerImg1,
    HomePatnerImg2,
    HomePatnerImg3,
    HomePatnerImg4,
    HomePatnerImg5,
    HomePatnerImg6,
];

const Partnerslider = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    arrows: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};

function Partnersblock() {
    return (
        <div>
            <div id="partners" className="partners-main-block-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Slider className="partners-slider" {...Partnerslider}>
                                {partnerImages.map((image, index) => (
                                    <div className="partners-logo" key={index}>
                                        <img src={image} className="img-fluid" alt="" />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Partnersblock;
