import React from 'react';
import { Link } from "react-router-dom";
import BlogDetailsImg1 from "../../assets/images/blog_details/blogdetails-01.png"
import BlogDetailsImg2 from "../../assets/images/blog_details/blogdetails-02.png"
import BlogDetailsImg3 from "../../assets/images/blog_details/blogdetails-03.png"
import BlogImg1 from "../../assets/images/blog_single/blog-01.png"
import BlogImg2 from "../../assets/images/blog_single/blog-02.png"
import BlogImg3 from "../../assets/images/blog_single/blog-03.png"
import BlogImg4 from "../../assets/images/blog_single/blog-04.png"

function Section() {
  return (
    <section id="blog-details" className="blog-details-main-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="blog-details-img mb_40">
                <img
                  src={BlogDetailsImg1}
                  className="img-fluid mb_20"
                  alt=""
                />
                <span>
                  <img
                    src={BlogDetailsImg2}
                    className="img-fluid"
                    alt=""
                  />
                  Alexa Martina
                </span>
                <span>
                  <i className="flaticon-comment"></i>No Comments
                </span>
              </div>
              <div className="blog-details-dtls">
                <h3 className="blog-details-heading mb_30">
                  Best Real Estate Group For your Next Level Destinations
                </h3>
                <p className="mb_20">
                  Embed a little help from our friends from time to time.
                  Although we offer the one-stop convenience of annery
                  integrated range of legal, financial services under one roof,
                  there are occasions when our clients specia- list advice
                  beyond the scope of our own expertise. That’s why we’ve
                  developed close working relationships with a number of
                  strategic partner
                </p>
                <p className="mb_30">
                  Embed a little help from our friends from time to time.
                  Although we offer the one-stop convenience of annery
                  integrated range of legal, financial services under one roof,
                  there are occasions when our clients areaneed specia- list
                  advice beyond the scope of our own expertise.
                </p>
              </div>
              <div className="blog-comment mb_40">
                <p>
                  <em>
                    “Embed a little help from our friends from time to time.
                    Although we offer the in one-stop convenience of annery
                    integrated range of legal
                  </em>
                </p>
              </div>
              <div className="blog-details-dtls-2">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="blog-details-img-2">
                      <img
                        src={BlogDetailsImg3}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <p className="mb_30">
                      Many extants variations of ipsum passages of Lorem Ipsum
                      availables, the majority of to have suffered
                    </p>
                    <ul className="blog-details-lst mb_40">
                      <li>
                        <i className="flaticon-check-mark"></i>Real Estate
                        Agency Program
                      </li>
                      <li>
                        <i className="flaticon-check-mark"></i>Architecture
                        Design Published
                      </li>
                      <li>
                        <i className="flaticon-check-mark"></i>Villa Apartment
                        Service Provided
                      </li>
                      <li>
                        <i className="flaticon-check-mark"></i>Regular
                        monitoring
                      </li>
                      <li>
                        <i className="flaticon-check-mark"></i>Best Services For
                        Security
                      </li>
                      <li>
                        <i className="flaticon-check-mark"></i>Regular update
                        monitoring
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="mb_40">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in a our
                  some form, by injected humour, or randomised words which don't
                  look even slightly believable. If you are going our as to use
                  a passage of Lorem Ipsum, you need to be sure there isn't
                  anything embarrassing hidden in the middle the of text. All
                  the Lorem Ipsum generators on the Internet tend.
                </p>
                <p className="mb_40">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in a our
                  some form, by injected humour, or randomised words which don't
                  look even slightly believable. If you are going our as to use
                  a passage of Lorem Ipsum, you need to be sure there isn't
                  anything embarrassing hidden in the middle the of text. All
                  the Lorem Ipsum generators on the Internet tend.
                </p>
                <div className="horizontal-line mb_30"></div>
                <div className="blog-tag">
                  <div className="row">
                    <div className="col-lg-6 text-start">
                      <h6 className="blog-tag-heading">Tag:</h6>
                      <p className="blog-tag-txt">
                        #Real Estate <span>#House Rent</span>#Villa Apartments
                      </p>
                    </div>
                    <div className="col-lg-6 text-end">
                      <h6 className="blog-tag-heading">Share:</h6>
                      <ul className="blog-tag-lst mb_20">
                        <li>
                          <Link to="#" title="">
                            <i className="flaticon-facebook-app-symbol"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#" title="">
                            <i className="flaticon-twitter-1"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#" title="">
                            <i className="flaticon-linkedin"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#" title="">
                            <i className="flaticon-pinterest"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#" title="">
                            <i className="flaticon-instagram"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="horizontal-line mb_40"></div>
              </div>
              <div className="blog-details-form">
                <h6 className="blog-details-heading-2 mb_30">
                  Leave a Comment
                </h6>
                <div className="row">
                  <div className="col-lg-4">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      autoComplete="on"
                      placeholder="Name"
                    />
                  </div>
                  <div className="col-lg-4">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      autoComplete="on"
                      placeholder="Email"
                    />
                  </div>
                  <div className="col-lg-4">
                    <input
                      type="text"
                      className="form-control"
                      id="website"
                      autoComplete="on"
                      placeholder="Website"
                    />
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      className="form-control mb_40"
                      id="comment"
                      placeholder="Comment"
                      rows="5"
                    ></textarea>
                    <div className="form-check mb_30">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                      >
                        Save my name, email, and website in this browser for the
                        next time I comment.
                      </label>
                    </div>
                  </div>
                  <div className="submit-btn">
                    <button type="submit" className="btn btn-primary">
                      Post Comment
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <div className="search-bar mb_40">
                <form className="d-flex" role="search">
                  <input
                    className="form-control me-2"
                    type="search"
                    id="search"
                    placeholder="Search Here . . ."
                    aria-label="Search"
                  />
                  <button className="btn" type="submit">
                    <i className="flaticon-search"></i>
                  </button>
                </form>
              </div>
              <div className="blog-categories mb_40">
                <h6 className="blog-categories-heading">Categories</h6>
                <div className="horizontal-line-3 mb_30"></div>
                <div className="category-link">
                  <div className="blog-links mb_20">
                    <Link to="#" title="">
                      Real Estate <span>(02)</span>
                    </Link>
                  </div>
                  <div className="horizontal-line mb_20"></div>
                  <div className="blog-links mb_20">
                    <Link to="#" title="">
                      {" "}
                      Apartment <span>(08)</span>
                    </Link>
                  </div>
                  <div className="horizontal-line mb_20"></div>
                  <div className="blog-links mb_20">
                    <Link to="#" title="">
                      House Rent <span>(05)</span>
                    </Link>
                  </div>
                  <div className="horizontal-line mb_20"></div>
                  <div className="blog-links mb_20">
                    <Link to="#" title="">
                      Architecture <span>(02)</span>
                    </Link>
                  </div>
                  <div className="horizontal-line mb_20"></div>
                  <div className="blog-links mb_20">
                    <Link to="#" title="">
                      {" "}
                      Villa Apartment <span>(11)</span>
                    </Link>
                  </div>
                  <div className="horizontal-line mb_20"></div>
                  <div className="blog-links mb_20">
                    <Link to="#" title="">
                      Agent <span>(12)</span>
                    </Link>
                  </div>
                  <div className="horizontal-line mb_20"></div>
                </div>
              </div>
              <div className="recent-post mb_40">
                <h6 className="recent-post-heading">Recent Post</h6>
                <div className="horizontal-line-3 mb_30"></div>
                <div className="recent-post-block mb_20">
                  <div className="row">
                    <div className="col-lg-4">
                      <Link to="/blog_details" title="">
                        <img
                          src={BlogImg1}
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="col-lg-8">
                      <div className="recent-post-lst">
                        <i className="flaticon-calendar"></i>
                        <span>22 Oct, 2024</span>
                        <p>
                          <Link to="/blog_details" title="">
                            Indeed base to a must be in a month
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recent-post-block mb_20">
                  <div className="row">
                    <div className="col-lg-4">
                      <Link to="/blog_details" title="">
                        <img
                          src={BlogImg2}
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="col-lg-8">
                      <div className="recent-post-lst">
                        <i className="flaticon-calendar"></i>
                        <span>22 Oct, 2024</span>
                        <p>
                          <Link to="/blog_details" title="">
                            Indeed base to a must be in a month
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recent-post-block mb_20">
                  <div className="row">
                    <div className="col-lg-4">
                      <Link to="/blog_details" title="">
                        <img
                          src={BlogImg3}
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="col-lg-8">
                      <div className="recent-post-lst">
                        <i className="flaticon-calendar"></i>
                        <span>22 Oct, 2024</span>
                        <p>
                          <Link to="/blog_details" title="">
                            Indeed base to a must be in a month
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recent-post-block mb_20">
                  <div className="row">
                    <div className="col-lg-4">
                      <Link to="/blog_details" title="">
                        <img
                          src={BlogImg4}
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="col-lg-8">
                      <div className="recent-post-lst">
                        <i className="flaticon-calendar"></i>
                        <span>22 Oct, 2024</span>
                        <p>
                          <Link to="/blog_details" title="">
                            Indeed base to a must be in a month
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-tags">
                <h6 className="recent-post-heading">Tags</h6>
                <div className="horizontal-line-3 mb_30"></div>
                <Link to="#" className="btn tag-btn" title="">
                  Real Estate
                </Link>
                <Link to="#" className="btn tag-btn" title="">
                  Architecture
                </Link>
                <Link to="#" className="btn tag-btn" title="">
                  Villa
                </Link>
                <Link to="#" className="btn tag-btn" title="">
                  Agent
                </Link>
                <Link to="#" className="btn tag-btn" title="">
                  Services
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Section
