import React, { useState } from "react";
import { Link } from "react-router-dom";
import ServiceDetailsImg1 from "../../assets/images/service_details/service-01.png";
import ServiceDetailsImg2 from "../../assets/images/service_details/service-02.png";
import ServiceDetailsImg3 from "../../assets/images/service_details/service-03.png";

function ServiceDetails() {

  const [activeSection, setActiveSection] = useState(0);

  const handleToggleSection = (sectionIndex) => {
    if (activeSection === sectionIndex) {
      setActiveSection(null);
    } else {
      setActiveSection(sectionIndex);
    }
  };

  const accordionItems = [
    {
      id: "headingOne",
      question: "What Is Payment system Services?",
      content:
        "When an unknown printer took a galley of type and scrambled it to make a type specimen bookhas a not only five centuries, but also the leap into electronic typesetting, remaining essentially unchan galley of type and scrambled it to make a type specimen book."
    },
    {
      id: "headingTwo",
      question: "How Many Service We Provide?",
      content:
        "When an unknown printer took a galley of type and scrambled it to make a type specimen bookhas a not only five centuries, but also the leap into electronic typesetting, remaining essentially unchan galley of type and scrambled it to make a type specimen book."
    },
    {
      id: "headingThree",
      question: "How Much Experience Our Team Member?",
      content:
        "When an unknown printer took a galley of type and scrambled it to make a type specimen bookhas a not only five centuries, but also the leap into electronic typesetting, remaining essentially unchan galley of type and scrambled it to make a type specimen book."
    }
  ];

  return (
    <section id="service-details" className="service-details-main-block">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="service-category mb_40">
              <h6 className="category-heading mb_20">Service Category</h6>
              <div className="service-link mb_20">
                <Link to="#">
                  Home Strategy <i className="flaticon-double-arrow"></i>
                </Link>
              </div>
              <div className="service-link mb_20">
                <Link to="#">
                  Property Progeny <i className="flaticon-double-arrow"></i>
                </Link>
              </div>
              <div className="service-link mb_20">
                <Link to="#">
                  Architecture Design <i className="flaticon-double-arrow"></i>
                </Link>
              </div>
              <div className="service-link mb_20">
                <Link to="#">
                  Land Property <i className="flaticon-double-arrow"></i>
                </Link>
              </div>
              <div className="service-link mb_20">
                <Link to="#">
                  Villa Apartment <i className="flaticon-double-arrow"></i>
                </Link>
              </div>
              <div className="service-link mb_20">
                <Link to="#">
                  House Rent Services <i className="flaticon-double-arrow"></i>
                </Link>
              </div>
            </div>
            <div className="service-contact mb_40">
              <h6 className="contact-heading mb_20">Contact Us</h6>
              <div className="horizontal-line mb_20"></div>
              <div className="contact-lst">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="lst-dtls">
                      <div className="row">
                        <div className="col-lg-1">
                          <i className="flaticon-location-1"></i>
                        </div>
                        <div className="col-lg-10">
                          <h6>Location</h6>
                          <p>Alison Street,10090, Cardon City,Canada.</p>
                        </div>
                      </div>
                    </div>
                    <div className="lst-dtls">
                      <div className="row">
                        <div className="col-lg-1">
                          <i className="flaticon-telephone-call"></i>
                        </div>
                        <div className="col-lg-10">
                          <h6>Call Us</h6>
                          <p>
                            <Link to="tel:" title="">
                              000-000-000 00
                            </Link>
                            <br />
                            <Link to="tel:" title="">
                              1234-000-000
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="lst-dtls">
                      <div className="row">
                        <div className="col-lg-1">
                          <i className="flaticon-email-1"></i>
                        </div>
                        <div className="col-lg-10">
                          <h6>Email</h6>
                          <p>
                            <Link to="mailto:" title="">
                              admin@rexain.com
                            </Link>
                            <br />
                            <Link to="mailto:" title="">
                              services@rexain.com
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-brochure mb_40">
              <h6 className="brochure-heading mb_20">Brochure</h6>
              <p className="mb_20">
                When an unknown printer took gallery offer type anybody
              </p>
              <div className="brochure-button">
                <Link to="#" className="btn-primary btn-brochure">
                  <i className="flaticon-pdf"></i> PDF Download
                </Link>
              </div>
              <div className="brochure-button">
                <Link to="#" className="btn-primary btn-brochure">
                  <i className="flaticon-pdf"></i> PDF Download
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="service-dtls-img">
              <img
                src={ServiceDetailsImg1}
                className="img-fluid mb_30"
                alt=""
              />
              <p>
                An architectural engineer is a professional who plays a crucial
                role in the planning, design, construction of buildings and
                other structures. They bridge the gap between architecture and
                engineering, combining creative and aesthetic aspects of design
                with the technical and structural considerations necessary for
                safe and buildings.{" "}
              </p>
            </div>
            <div className="about-service">
              <h5 className="about-service-heading mb_30">
                Best Real Estate Group For your Next Destinations
              </h5>
              <p className="mb_30">
                Embed a little help from our friends from time to time. Although
                we offer the one-stop convenience of annery integrated range of
                legal, financial services under one roof, there are occasions
                when our clients specia- list advice beyond the scope of our own
                expertise. Thats why weve developed close working relationships
                with a number of strategic partner
              </p>
              <div className="service-list mb_40">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="service-list-dtls">
                          <i className="flaticon-check-mark"></i>
                          <p>100% Payment Secure</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="service-list-dtls">
                          <i className="flaticon-check-mark"></i>
                          <p>Happy Customers</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="service-list-dtls">
                          <i className="flaticon-check-mark"></i>
                          <p>Budget Friend House</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="service-list-dtls">
                          <i className="flaticon-check-mark"></i>
                          <p>Valuable Builders</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-type mb_40">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="serivce-type-block">
                      <div className="row">
                        <div className="col-lg-2 text-center">
                          <i className="flaticon-residential"></i>
                        </div>
                        <div className="col-lg-10">
                          <h6 className="available-service-heading">
                            Apartment Services
                          </h6>
                          <p>
                            eed are little help fromery fri of anery integrated
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="serivce-type-block">
                      <div className="row">
                        <div className="col-lg-2 text-center">
                          <i className="flaticon-home-1"></i>
                        </div>
                        <div className="col-lg-10">
                          <h6 className="available-service-heading">
                            House Rent
                          </h6>
                          <p>
                            eed are little help fromery fri of anery integrated
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-service-2">
              <h5 className="about-service-heading mb_40">
                Best Quality Material Used For Building.
              </h5>
              <p className="mb_30">
                Embed a little help from our friends from time to time. Although
                we offer the one-stop convenience of annery integrated range of
                legal, financial services under one roof, there are occasions
                when our clients specia- list advice beyond the scope of our own
                expertise. Thats why weve developed close working relationships
                with a number of strategic partner
              </p>
              <div className="about-service-lst mb_30">
                <ul>
                  <li>
                    <Link to="#" title="">
                      <img
                        src={ServiceDetailsImg2}
                        className="img-fluid mb_20"
                        alt=""
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" title="">
                      <img
                        src={ServiceDetailsImg3}
                        className="img-fluid mb_20"
                        alt=""
                      />
                    </Link>
                  </li>
                </ul>
              </div>
              <p className="mb_40">
                Embed a little help from our friends from time to time. Although
                we offer the one-stop convenience of annery integrated range of
                legal, financial services under one roof, there are occasions
                when our clients specia- list advice beyond the scope of our own
                expertise. Thats why weve developed close working relationships
                with a number of strategic partner
              </p>
            </div>
            <div className="about-service-3">
              <h5 className="about-service-heading mb_30">
                Any questions find here
              </h5>
              <p className="mb_40">
                when an unknown printer took a galley of type and scrambled it
                to make a type specimen bookhas a not only five centuries, but
                also the leap into electronic typesetting.
              </p>
              <div className="accordion" id="accordionExample">
                {accordionItems.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h5 className="accordion-header" id={item.id}>
                      <button
                        className={`accordion-button ${activeSection === index ? '' : 'collapsed'}`}
                        type="button"
                        onClick={() => handleToggleSection(index)}
                      >
                        {item.question}
                      </button>
                    </h5>
                    <div
                      id={`collapse${item.id}`}
                      className={`accordion-collapse collapse ${activeSection === index ? 'show' : ''}`}
                      aria-labelledby={item.id}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {item.content}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceDetails;
