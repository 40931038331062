import React from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import HomeSliderImg3 from "../../assets/images/slider/slider-03.png";
import HomeSliderImg4 from "../../assets/images/slider/slider-04.png";
import HomeSliderImg5 from "../../assets/images/slider/slider-05.png";
import HomeSliderImg6 from "../../assets/images/slider/slider-06.png";
import HomeSliderImg14 from "../../assets/images/slider/slider-14.png";
import HomeSliderImg13 from "../../assets/images/slider/slider-13.png";
import HomeSliderImg15 from "../../assets/images/slider/slider-15.png";
import HomeSliderImg16 from "../../assets/images/slider/slider-16.png";

const HomeThreeSlider = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };

  const SliderThreeSetting = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

function SliderThree() {
    return (
        <div>
            <section id="slider" className="slider-main-block-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="slider-dtls-3">
                                <h2 className="slider-3-heading">
                                    Choose the right home for your lifestyle
                                </h2>
                                <p className="slider-3-txt">
                                    Real estate group, a dynamic and diverse community of
                                    professionals, plays a pivotal role in shaping the property
                                    landscape. Comprising real estate agents, brokers, developers,
                                    investors, and constious support staff
                                </p>
                                <Link
                                    to="#"
                                    className="btn pointer-zoom btn-primary explore-btn"
                                >
                                    Explore More
                                </Link>
                                <div className="play-btn-2">
                                    <Link to="#" title="" className="pointer-zoom play-btn-icon">
                                        <i className="flaticon-play-button-arrowhead"></i>
                                    </Link>
                                    <Link
                                        to="#"
                                        className="btn pointer-zoom btn-primary play-btn-txt"
                                        title=""
                                    >
                                        Watch Videos
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="slider-image-3">
                                <Slider className="slider slide-show" {...HomeThreeSlider}>
                                    <div className="slider-img-3">
                                        <img
                                            src={HomeSliderImg3}
                                            className="img-fluid show-img"
                                            alt=""
                                        />
                                    </div>
                                    <div className="slider-img-3">
                                        <img
                                            src={HomeSliderImg14}
                                            className="img-fluid show-img"
                                            alt=""
                                        />
                                    </div>
                                    <div className="slider-img-3">
                                        <img
                                            src={HomeSliderImg15}
                                            className="img-fluid show-img"
                                            alt=""
                                        />
                                    </div>
                                    <div className="slider-img-3">
                                        <img
                                            src={HomeSliderImg16}
                                            className="img-fluid show-img"
                                            alt=""
                                        />
                                    </div>
                                    <div className="slider-img-3">
                                        <img
                                            src={HomeSliderImg16}
                                            className="img-fluid show-img"
                                            alt=""
                                        />
                                    </div>
                                    <div className="slider-img-3">
                                        <img
                                            src={HomeSliderImg16}
                                            className="img-fluid show-img"
                                            alt=""
                                        />
                                    </div>
                                </Slider>
                                <Slider className="img-lst-slider" {...SliderThreeSetting}>
                                        <div className="img-block">
                                            <Link to="#" title="">
                                                <img
                                                    src={HomeSliderImg13}
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                        <div className="img-block">
                                            <Link to="#" title="">
                                                <img
                                                    src={HomeSliderImg5}
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                        <div className="img-block">
                                            <Link to="#" title="">
                                                <img
                                                    src={HomeSliderImg6}
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                        <div className="img-block">
                                            <Link to="#" title="">
                                                <img
                                                    src={HomeSliderImg4}
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                        <div className="img-block">
                                            <Link to="#" title="">
                                                <img
                                                    src={HomeSliderImg6}
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                        <div className="img-block">
                                            <Link to="#" title="">
                                                <img
                                                    src={HomeSliderImg4}
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="triangle"></div>
            </section>
        </div>
    )
}

export default SliderThree