import React from 'react';
import { Link } from 'react-router-dom';
import PostImg2 from "../../assets/images/post/post-02.png";
import PostImg3 from "../../assets/images/post/post-03.png";
import PostImg4 from "../../assets/images/post/post-04.png";

const posts = [
    {
        img: PostImg2,
        title: "We are creating Your Dream House that are both",
        date: "June 10, 2024",
        link: "/blog_details",
        author: "Admin"
    },
    {
        img: PostImg3,
        title: "We are Showing London City Tower place Latest News Apartment Sale",
        date: "June 10, 2024",
        link: "/blog_details",
        author: "Admin"
    },
    {
        img: PostImg4,
        title: "Dubai Mall Apartment Marketing For Selling Analysis News Reports",
        date: "June 10, 2024",
        link: "/blog_details",
        author: "Admin"
    }
];

function Blogtwo() {
    return (
        <div>
            <section id="blog" className="blog-main-block-2">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section">
                                <h6 className="section-sub-heading">News &amp; Blogs</h6>
                                <h3 className="section-heading mb_60">
                                    Explore Our Latest
                                    <br />
                                    News & Blogs
                                </h3>
                            </div>
                        </div>
                        {posts.map((post, index) => (
                            <div className="col-lg-4" key={index}>
                                <div className="post-block-2">
                                    <div className="post-img">
                                        <Link to={post.link} title="">
                                            <img src={post.img} className="img-fluid" alt="" />
                                        </Link>
                                        <div className="post-user">
                                            <Link to="/blog_single" className="btn pointer-zoom btn-outline-primary" title="">
                                                <i className="flaticon-user"></i> {post.author}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="post-dtls">
                                        <ul>
                                            <li><Link to="#" title="">Design Structure</Link></li>
                                            <li>/</li>
                                            <li><Link to="#" title="">{post.date}</Link></li>
                                        </ul>
                                        <Link to="#" title=""></Link>
                                    </div>
                                    <div className="horizontal-line"></div>
                                    <h6 className="post-title">
                                        <Link to={post.link} title="">{post.title}</Link>
                                    </h6>
                                    <Link to={post.link} className="btn pointer-zoom btn-outline-primary" title="">Read More</Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Blogtwo;
