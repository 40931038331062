import React from "react";
import HeaderOne from "../HeaderOne/Main";
import Breadcrumb from "../Breadcrumb/Main";
import TeamMember from "../Menteam/TeamMember";
import Newsletter from "../Menhomeone/Newsletter";
import FooterOne from "../FooterOne/Main";

function Main() {
  return (
    <div>
      <div className="pointer"></div>
      <HeaderOne />
      <Breadcrumb title="Team Members" subtitle="Home" />
      <TeamMember />
      <Newsletter />
      <FooterOne />
    </div>
  );
}

export default Main;
