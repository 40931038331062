import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import ServiceBgImg2 from '../../assets/images/bg/service-2-bg.png';

const Servicefourslider = {
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: false,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};

const services = [
    {
        icon: 'flaticon-flat',
        title: 'Apartments',
        description: 'We Design and services Next Generations For your office, buildings, Apartments Services for your next plan.',
    },
    {
        icon: 'flaticon-coworkers',
        title: 'Business Space',
        description: 'We Design and services Next Generations For your office, buildings, Apartments Services for your next plan.',
    },
    {
        icon: 'flaticon-modern-house',
        title: 'VIlla House',
        description: 'We Design and services Next Generations For your office, buildings, Apartments Services for your next plan.',
    },
    {
        icon: 'flaticon-flat',
        title: 'Apartments',
        description: 'We Design and services Next Generations For your office, buildings, Apartments Services for your next plan.',
    },
];

function Serviceblock() {
    return (
        <div>
            <section
                id="services"
                className="services-main-block-4 parallax"
                style={{ backgroundImage: `url(${ServiceBgImg2})` }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section text-center">
                                <h6 className="section-sub-heading">
                                    <i className="flaticon-dot dot-icon"></i>Services
                                </h6>
                                <h3 className="section-heading mb_60">
                                    Our Service That We Provide
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Slider className="service-slider-4" {...Servicefourslider}>
                            {services.map((service, index) => (
                                <div className="services-block-4" key={index}>
                                    <div className="services-icon-4">
                                        <i className={service.icon}></i>
                                    </div>
                                    <div className="services-details">
                                        <h5 className="services-heading">
                                            <Link to="/service_details" title="">
                                                {service.title}
                                            </Link>
                                        </h5>
                                        <p>{service.description}</p>
                                    </div>
                                    <Link
                                        to="/service_details"
                                        className="btn pointer-zoom btn-link"
                                        title=""
                                    >
                                        View more <i className="flaticon-right-arrow"></i>
                                    </Link>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Serviceblock;
