import React from "react";
import HeaderOne from "../HeaderOne/Main";
import Breadcrumb from "../Breadcrumb/Main";
import ServiceDetails from "../Menservicedtls/ServiceDetails";
import Newsletter from "../Menhomeone/Newsletter";
import FooterOne from "../FooterOne/Main";


function Main() {
  return (
    <div>
      <HeaderOne />
      <Breadcrumb title="Service Details" subtitle="Home" />
      <ServiceDetails />
      <Newsletter />
      <FooterOne />
    </div>
  );
}

export default Main;
