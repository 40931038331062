import React from 'react'
import { Link } from "react-router-dom";
import HomeSliderImg1 from "../../assets/images/slider/slider-01.png";
import HomeSliderImg2 from "../../assets/images/slider/slider-02.png";

function sliderone() {
    return (
        <div>
            <section id="slider" className="slider-main-block">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="slider-dtls">
                                <h2 className="slider-heading">
                                    Hayallerinizi
                                    <br />
                                    <span className="span-1">Gerçeğe</span>
                                    <br />
                                    <span className="span-2">Dönüştürün</span>
                                </h2>
                                <h6 className="slider-heading-2">
                                    <span className="span-2">
                                        Rüya Eviniz
                                        <br />
                                        Son Projeniz Olsun
                                    </span>
                                </h6>
                                <div className="row">
                                    <div className="col-lg-6 col-6">
                                        <Link to="#" className="btn btn-primary slider-btn-1 pointer-zoom" title="">
                                            Kaydedin{" "}
                                        </Link>
                                        <span className="round"></span>
                                    </div>
                                    <div className="col-lg-6 col-6">
                                        <h2 className="slider-heading-3"></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-img mb_40">
                                <div className="slider-house-img">
                                    <div className="hover-img">
                                        <div className="hover-action" data-scale="1.15" data-image={HomeSliderImg1}>
                                            <div className="moving-image" style={{ backgroundImage: `url(${HomeSliderImg1})`, }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-9">
                                    <h4 className="slider-heading-4">
                                        Konut Alanı Projesi
                                    </h4>
                                </div>
                                <div className="col-lg-6 col-3">
                                    <Link to="#" className="btn btn-primary pointer-zoom slider-btn-2" title="">
                                        Devamını Oku
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="slider-img-2">
                                <div className="slider-building-img">
                                    <div className="hover-img">
                                        <div className="hover-action bor-radius" data-scale="1.15" data-image={HomeSliderImg2}>
                                            <div className="moving-image" style={{ backgroundImage: `url(${HomeSliderImg2})`, }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="estate-img">
                                    <i className="flaticon-right-up-1"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default sliderone