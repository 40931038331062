import React from 'react'
import { Link } from 'react-router-dom'
import SliderBgImg2 from "../../assets/images/bg/slider-bg-2.png";
import Slider from 'react-slick';

const Slidertwo = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    speed: 1000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1000,
    centerMode: false,
    centerPadding: "180px",
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};


function SliderTwo() {
    return (
        <div>
            <section id="slider" className="slider-main-block-2 parallax" style={{ backgroundImage: `url(${SliderBgImg2})` }}>
                <div className="container-fluid">
                    <div className="row">
                        <Slider className="slider-2" {...Slidertwo}>
                            <div className="slider-dtls-2">
                                <p className="slider-2-txt">Kızıltan Gayrimenkul Yatırım</p>
                                <h2 className="slider-2-heading">
                                    Creative Style
                                    <br />
                                    For Your Next
                                    <br />
                                    Home
                                </h2>
                                <Link
                                    to="#"
                                    className="pointer-zoom btn btn-primary explore-btn"
                                    title=""
                                >
                                    Explore More
                                </Link>
                                <div className="play-btn-2">
                                    <Link
                                        to="#"
                                        title=""
                                        className="pointer-zoom play-btn-icon"
                                    >
                                        <i className="flaticon-play"></i>{" "}
                                    </Link>
                                    <Link
                                        to="#"
                                        className="btn pointer-zoom btn-primary play-btn-txt"
                                        title=""
                                    >
                                        View Our Project
                                    </Link>
                                </div>
                            </div>
                            <div className="slider-dtls-2">
                                <p className="slider-2-txt">Kızıltan Gayrimenkul Yatırım</p>
                                <h2 className="slider-2-heading">
                                    Creative Style
                                    <br />
                                    For Your Next
                                    <br />
                                    Home
                                </h2>
                                <Link
                                    to="#"
                                    className="pointer-zoom btn btn-primary explore-btn"
                                    title=""
                                >
                                    Explore More
                                </Link>
                                <div className="play-btn-2">
                                    <Link
                                        to="#"
                                        title=""
                                        className="pointer-zoom play-btn-icon"
                                    >
                                        <i className="flaticon-play"></i>{" "}
                                    </Link>
                                    <Link
                                        to="#"
                                        className="btn pointer-zoom btn-primary play-btn-txt"
                                        title=""
                                    >
                                        View Our Project
                                    </Link>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
                <div className="slider-lst">
                    <ul>
                        <li>
                            <Link to="#" title="">
                                <i className="flaticon-facebook-app-symbol"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" title="">
                                <i className="flaticon-pinterest"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" title="">
                                <i className="flaticon-instagram"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" title="">
                                <i className="flaticon-twitter-1"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" title="">
                                <i className="flaticon-linkedin"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    )
}

export default SliderTwo