import React from 'react'
import { Link } from 'react-router-dom'
import PropertiesImg1 from "../../assets/images/properties/properties-01.png";
import PropertiesImg2 from "../../assets/images/properties/properties-02.png";
import PropertiesImg3 from "../../assets/images/properties/properties-03.png";
import Slider from 'react-slick';


const propertiesSlider = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplay: true,
    autoplayspeed: 2000,
    arrows: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};

function Propertiesblock() {
    return (
        <div>
            <section id="properties" className="properties-main-block-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-12">
                            <div className="section">
                                <h6 className="section-sub-heading">Properties</h6>
                                <h3 className="section-heading mb_60">
                                    explore Our Latest &amp; Featured Properties
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Slider className="properties-slider" {...propertiesSlider}>
                            <div className="properties-block">
                                <div className="properties-img">
                                    <div className="features-sale">
                                        <div className="row">
                                            <div className="col-lg-6 text-start">
                                                <span className="badge feature-bd">Featured</span>
                                            </div>
                                            <div className="col-lg-6 text-end">
                                                <span className="badge sale-bd">For Sale</span>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="#" title="">
                                        <img
                                            src={PropertiesImg1}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </a>
                                </div>
                                <div className="properties-dtls">
                                    <div className="properties-location">
                                        <a href="#" title="">
                                            <i className="flaticon-location" /> 1384 Rue Jean-Talon Est,
                                            Montréal, CA
                                        </a>
                                    </div>
                                    <h5 className="properties-heading">
                                        <a href="#" title="">
                                            Remon Luxury
                                            <br />
                                            Apartments
                                        </a>
                                    </h5>
                                    <ul className="properties-features-2">
                                        <li>
                                            <i className="flaticon-bed" />4 Bed Room
                                        </li>
                                        <li>
                                            <i className="flaticon-bathtub" />3 Baths
                                        </li>
                                        <li>
                                            <i className="flaticon-network" />
                                            1300 sqr
                                        </li>
                                    </ul>
                                    <div className="properties-price">$1,10,809</div>
                                    <a
                                        href="#"
                                        className="btn pointer-zoom btn-outline-primary"
                                        title=""
                                    >
                                        More Details
                                    </a>
                                </div>
                            </div>
                            <div className="properties-block">
                                <div className="properties-img">
                                    <div className="features-sale">
                                        <div className="row">
                                            <div className="col-lg-6 text-start">
                                                <span className="badge feature-bd">Featured</span>
                                            </div>
                                            <div className="col-lg-6 text-end">
                                                <span className="badge sale-bd">For Sale</span>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="#" title="">
                                        <img
                                            src={PropertiesImg2}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </a>
                                </div>
                                <div className="properties-dtls">
                                    <div className="properties-location">
                                        <a href="#" title="">
                                            <i className="flaticon-location" /> 1384 Rue Jean-Talon Est,
                                            Montréal, CA
                                        </a>
                                    </div>
                                    <h5 className="properties-heading">
                                        <a href="#" title="">
                                            Remon Luxury
                                            <br />
                                            Apartments
                                        </a>
                                    </h5>
                                    <ul className="properties-features-2">
                                        <li>
                                            <i className="flaticon-bed" />4 Bed Room
                                        </li>
                                        <li>
                                            <i className="flaticon-bathtub" />3 Baths
                                        </li>
                                        <li>
                                            <i className="flaticon-network" />
                                            1300 sqr
                                        </li>
                                    </ul>
                                    <div className="properties-price">$1,10,809</div>
                                    <a
                                        href="#"
                                        className="btn pointer-zoom btn-outline-primary"
                                        title=""
                                    >
                                        More Details
                                    </a>
                                </div>
                            </div>
                            <div className="properties-block">
                                <div className="properties-img">
                                    <div className="features-sale">
                                        <div className="row">
                                            <div className="col-lg-6 text-start">
                                                <span className="badge feature-bd">Featured</span>
                                            </div>
                                            <div className="col-lg-6 text-end">
                                                <span className="badge sale-bd">For Sale</span>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="#" title="">
                                        <img
                                            src={PropertiesImg3}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </a>
                                </div>
                                <div className="properties-dtls">
                                    <div className="properties-location">
                                        <a href="#" title="">
                                            <i className="flaticon-location" /> 1384 Rue Jean-Talon Est,
                                            Montréal, CA
                                        </a>
                                    </div>
                                    <h5 className="properties-heading">
                                        <a href="#" title="">
                                            Rajmon Villa
                                            <br />
                                            Apartment
                                        </a>
                                    </h5>
                                    <ul className="properties-features-2">
                                        <li>
                                            <i className="flaticon-bed" />4 Bed Room
                                        </li>
                                        <li>
                                            <i className="flaticon-bathtub" />3 Baths
                                        </li>
                                        <li>
                                            <i className="flaticon-network" />
                                            1300 sqr
                                        </li>
                                    </ul>
                                    <div className="properties-price">$80,809</div>
                                    <a
                                        href="#"
                                        className="btn pointer-zoom btn-outline-primary"
                                        title=""
                                    >
                                        More Details
                                    </a>
                                </div>
                            </div>
                            <div className="properties-block">
                                <div className="properties-img">
                                    <div className="features-sale">
                                        <div className="row">
                                            <div className="col-lg-6 text-start">
                                                <span className="badge feature-bd">Featured</span>
                                            </div>
                                            <div className="col-lg-6 text-end">
                                                <span className="badge sale-bd">For Sale</span>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="#" title="">
                                        <img
                                            src="assets/images/properties/properties-01.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </a>
                                </div>
                                <div className="properties-dtls">
                                    <div className="properties-location">
                                        <a href="#" title="">
                                            <i className="flaticon-location" /> 1384 Rue Jean-Talon Est,
                                            Montréal, CA
                                        </a>
                                    </div>
                                    <h5 className="properties-heading">
                                        <a href="#" title="">
                                            Garden City
                                            <br />
                                            Apartments
                                        </a>
                                    </h5>
                                    <ul className="properties-features-2">
                                        <li>
                                            <i className="flaticon-bed" />4 Bed Room
                                        </li>
                                        <li>
                                            <i className="flaticon-bathtub" />3 Baths
                                        </li>
                                        <li>
                                            <i className="flaticon-network" />
                                            1300 sqr
                                        </li>
                                    </ul>
                                    <div className="properties-price">$1,12,809</div>
                                    <a
                                        href="#"
                                        className="btn pointer-zoom btn-outline-primary"
                                        title=""
                                    >
                                        More Details
                                    </a>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default Propertiesblock