import React from 'react';
import HomePatnerImg1 from '../../assets/images/partners/partners-01.png';
import HomePatnerImg2 from '../../assets/images/partners/partners-02.png';
import HomePatnerImg3 from '../../assets/images/partners/partners-03.png';
import HomePatnerImg4 from '../../assets/images/partners/partners-04.png';
import HomePatnerImg5 from '../../assets/images/partners/partners-05.png';
import HomePatnerImg6 from '../../assets/images/partners/partners-06.png';

const partnerImages = [
    HomePatnerImg1,
    HomePatnerImg2,
    HomePatnerImg3,
    HomePatnerImg4,
    HomePatnerImg5,
    HomePatnerImg6,
];

function Partners() {
    return (
        <div>
            <section id="partners" className="partners-main-block">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-12">
                            <div className="row">
                                {partnerImages.map((image, index) => (
                                    <div key={index} className="col-lg-4">
                                        <div className="partners-logo">
                                            <img src={image} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="partners-section">
                                <h4 className="partners-section-title">
                                    <span>1590</span>'dan fazla Dünyanın Lider Emlak acentesinin güvendiği{' '}
                                    <span>Kızıltan Gayrimenkul Yatırım</span>
                                </h4>
                                <p>
                                    Kızıltan Gayrimenkul Yatırım olarak gayrimenkul işlemlerinin sadece gayrimenkullerden ibaret olmadığının bilincindeyiz.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Partners;
