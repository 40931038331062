import React from 'react'
import HeaderOne from '../HeaderOne/Main'
import Sliderone from '../Menhomeone/Sliderone'
import Partners from '../Menhomeone/Partners'
import Servicedtlsone from '../Menhomeone/Servicedtlsone'
import Abtone from '../Menhomeone/Abtone'
import Projectone from '../Menhomeone/Projectone'
import Properties from '../Menhomeone/Properties'
import Video from '../Menhomeone/Video'
import Testimonial from '../Menhomeone/Testimonial'
import Teamone from '../Menhomeone/Teamone'
import Faq from '../Menhomeone/Faq'
import Blog from '../Menhomeone/Blog'
import Newsletter from '../Menhomeone/Newsletter'
import FooterOne from '../FooterOne/Main'

function Main() {
  return (
    <div>
      <HeaderOne />
      <Sliderone />
      {/* <Partners /> */}
      <Servicedtlsone />
      <Abtone />
      {/* <Projectone /> */}
      {/* <Properties /> */}
      {/* <Video /> */}
      {/* <Testimonial /> */}
      {/* <Teamone /> */}
      {/* <Faq /> */}
      {/* <Blog /> */}
      {/* <Newsletter /> */}
      <FooterOne />
    </div>
  )
}

export default Main
