import React from "react";
import HeaderOne from "../HeaderOne/Main";
import Breadcrumb from "../Breadcrumb/Main";
import Member from "../Menteamdtls/Member";
import Newsletter from "../Menhomeone/Newsletter";
import FooterOne from "../FooterOne/Main";


function Main() {
  return (
    <div>
      <HeaderOne />
      <Breadcrumb title="Duyurular" subtitle="Anasayfa" />
      <Member />
      {/* <Newsletter /> */}
      <FooterOne />
    </div>
  );
}

export default Main;
