import React from 'react';
import { Link } from 'react-router-dom';
import ContactImg1 from "../../assets/images/contact/contact-01.png"

function Contact() {
  return (
    <div>
      <section id="contact-us" className="contact-us-main-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-12">
              <div className="contact-us-img text-end">
                <img
                  src={ContactImg1}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="offset-lg-1 col-lg-5 col-12">
              <div className="contact-us-block">
                <h3 className="contact-us-heading mb_30">Adreslerimiz</h3>
                <div className="horizontal-line-3"></div>
                <div className="contact-us-dtls">
                  <h6 className="contact-us-sub-heading mb_30">Merkez Ofis</h6>
                  <ul className="contact-dtls-lst">
                    <li>
                      Atatürk Mah. Adnan Menderes Cad. No: 8
                      <br />
                      Esenyurt 34513 İstanbul - Türkiye
                    </li>
                    <li>
                      <Link to="tel:" title="">
                        +90 (212) 886-7523
                      </Link>
                    </li>
                    <li>
                      <Link to="mailto:" title="">
                        info@kiziltangayrimenkul.com.tr
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="contact-us-dtls">
                  <h6 className="contact-us-sub-heading mb_30">
                    Çerkezköy Ofis
                  </h6>
                  <ul className="contact-dtls-lst-2">
                    <li>
                      Çerkezköy Organize Sanayi Bölgesi Karaağaç Mahallesi 10.Sokak No:2
                      <br />
                      Çerkezköy - Tekirdağ - Türkiye
                    </li>
                    <li>
                      <Link to="tel:" title="">
                        +90 (282) 758-1890
                      </Link>
                    </li>
                    <li>
                      <Link to="mailto:" title="">
                        info@kiziltangayrimenkul.com.tr
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

export default Contact
