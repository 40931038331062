import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogoImg2 from "../../assets/images/general/logo.png";

function Main() {
  const [homeDropDown, setHomeDropDown] = useState(false);
  const [serviceDropDown, setServiceDropDown] = useState(false);
  const [pageDropDown, setPageDropDown] = useState(false);
  const [navShow, setNavShow] = useState(false);

  const toggleMobileMenu = () => {
    setNavShow(!navShow);
  };

  const toggleHomeDropDown = () => {
    setHomeDropDown(!homeDropDown);
    setServiceDropDown(false);
    setPageDropDown(false);
  };

  const toggleServiceDropDown = () => {
    setServiceDropDown(!serviceDropDown);
    setHomeDropDown(false);
    setPageDropDown(false);
  };

  const togglePageDropDown = () => {
    setPageDropDown(!pageDropDown);
    setHomeDropDown(false);
    setServiceDropDown(false);
  };

  return (
    <div>
      <div id="top-menu" className="top-menu-main-block-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="owner-dtls text-start">
                <ul>
                  <li>
                    <i className="flaticon-location" />
                    Alison Street,10090,Canada
                  </li>
                  <li>
                    <i className="flaticon-call-1" />
                    <Link to="tel:" title="">
                      997-889-0987
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 text-end col-12">
              <div className="user-login">
                <Link to="#" title="">
                  <i className="flaticon-user-1" />
                  Login/Register
                </Link>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-6">
              <div className="rexain-logo">
                <Link to="/" title="">
                  <img
                    src={LogoImg2}
                    alt=""
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-8 col-8">
              <div className="navigation">
                <nav className="navbar navbar-expand-lg">
                  <button className="navbar-toggler" data-bs-toggle="collapse"
                    aria-controls="navbarSupportedContent" type="button" aria-expanded="false"
                    onClick={toggleMobileMenu} aria-label="Toggle navigation" >
                    <span>
                      <i className="flaticon-menu"></i>
                    </span>
                  </button>
                  <div
                    className={
                      navShow
                        ? "collapse navbar-collapse show"
                        : "collapse navbar-collapse"
                    }
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      <li className="nav-item dropdown active">
                        <Link className="nav-link pointer-zoom dropdown-toggle"
                          to="#" role="button"
                          data-bs-toggle="dropdown"
                          onClick={toggleHomeDropDown}
                          aria-expanded={homeDropDown ? "true" : "false"}

                        >
                          Home<i className="flaticon-down-chevron"></i>
                        </Link>
                        <ul
                          className={
                            homeDropDown ? "dropdown-menu show" : "dropdown-menu"
                          }
                        >
                          <li>
                            <Link
                              className="dropdown-item pointer-zoom pointer-zoom active"
                              to="/"
                              onClick={toggleHomeDropDown}
                            >
                              Home 1
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item pointer-zoom"
                              to="/hometwo"
                              onClick={toggleHomeDropDown}
                            >
                              Home 2
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item pointer-zoom"
                              to="/homethree"
                              onClick={toggleHomeDropDown}
                            >
                              Home 3
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item pointer-zoom"
                              to="/homefour"
                              onClick={toggleHomeDropDown}
                            >
                              Home 4
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link pointer-zoom dropdown-toggle"
                          to="/about"
                        >
                          About Us
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link pointer-zoom dropdown-toggle"
                          to="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          onClick={toggleServiceDropDown}
                          aria-expanded={serviceDropDown ? "true" : "false"}
                        >
                          Service<i className="flaticon-down-chevron"></i>
                        </Link>
                        <ul
                          className={
                            serviceDropDown
                              ? "dropdown-menu show"
                              : "dropdown-menu"
                          }
                        >
                          <li>
                            <Link
                              className="dropdown-item pointer-zoom"
                              to="/service"
                            >
                              Services
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item pointer-zoom"
                              to="/service_details"
                            >
                              Service details
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item pointer-zoom dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          onClick={togglePageDropDown}
                          aria-expanded={pageDropDown ? "true" : "false"}
                        >
                          Page<i className="flaticon-down-chevron"></i>
                        </Link>
                        <ul
                          className={
                            pageDropDown ? "dropdown-menu show" : "dropdown-menu"
                          }
                        >
                          <li>
                            <Link
                              className="dropdown-item pointer-zoom"
                              to="/team_members"
                            >
                              Teams
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item pointer-zoom"
                              to="/team_detail"
                            >
                              Team Detail
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item pointer-zoom"
                              to="/blog_single"
                            >
                              Blog
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item pointer-zoom"
                              to="/blog_details"
                            >
                              Blog Details
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item pointer-zoom"
                              to="/error_page"
                            >
                              404 Page
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link pointer-zoom" to="/contact_us">
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <div className="col-lg-2 col-12">
              <div className="navbar-btn text-end">
                <Link
                  to="#"
                  title=""
                  className="btn btn-primary pointer-zoom visit-btn"
                >
                  Shedule a Visit
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Main;
