import React from 'react'
import { Link } from "react-router-dom";
import PostImg8 from "../../assets/images/post/post-08.png";
import PostImg9 from "../../assets/images/post/post-09.png";
import PostImg10 from "../../assets/images/post/post-10.png";

function Blogfour() {
    return (
        <div>
            <section id="blog" className="blog-main-block-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="section mb-30">
                                <h6 className="section-sub-heading">
                                    <i className="flaticon-dot dot-icon"></i>News & BLOGS
                                </h6>
                                <h3 className="section-heading">
                                    Release Latest <br />
                                    News & Blogs
                                </h3>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="sub-section-2 text-end">
                                <Link
                                    to="/blog_details"
                                    className="btn pointer-zoom btn-link"
                                    title=""
                                >
                                    View All Blogs <i className="flaticon-right-arrow-1"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="horizontal-line mb_30"></div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="post-block-4">
                                <div className="post-img-4">
                                    <Link to="/blog_details" title="">
                                        <img
                                            src={PostImg8}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="post-date-4">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="post-dt-1">
                                                <Link to="#" title="">
                                                    Investing in Real Estate
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="post-dt-2 text-end">
                                                <Link to="#" title="">
                                                    05 APRIL,2024
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-dtls">
                                    <h6 className="post-title">
                                        <Link to="/blog_details" title="">
                                            We are creating Your
                                            <br /> Dream House that are both
                                        </Link>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="post-block-4">
                                <div className="post-img-4">
                                    <Link to="/blog_details" title="">
                                        <img
                                            src={PostImg9}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="post-date-4">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="post-dt-1">
                                                <Link to="#" title="">
                                                    Project Sale Campaign
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="post-dt-2 text-end">
                                                <Link to="#" title="">
                                                    05 APRIL,2024
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-dtls">
                                    <h6 className="post-title">
                                        <Link to="/blog_details" title="">
                                            Release Our New
                                            <br />
                                            Project Sale Campaign
                                        </Link>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="post-block-4">
                                <div className="post-img-4">
                                    <Link to="/blog_details" title="">
                                        <img
                                            src={PostImg10}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="post-date-4">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="post-dt-1">
                                                <Link to="#" title="">
                                                    Investing in Real Estate
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="post-dt-2 text-end">
                                                <Link to="#" title="">
                                                    05 APRIL,2024
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-dtls">
                                    <h6 className="post-title">
                                        <Link to="/blog_details" title="">
                                            Explore Our Latest Offer
                                            <br />
                                            Price in Campaign
                                        </Link>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Blogfour