import React from 'react';
import { Link } from 'react-router-dom';
import TeamMember1 from "../../assets/images/team_members/member-01.png"
import TeamMember2 from "../../assets/images/team_members/member-02.png"
import TeamMember3 from "../../assets/images/team_members/member-03.png"
import TeamMember4 from "../../assets/images/team_members/member-04.png"
import TeamMember5 from "../../assets/images/team_members/member-05.png"
import TeamMember6 from "../../assets/images/team_members/member-06.png"
import TeamMember7 from "../../assets/images/team_members/member-07.png"
import TeamMember8 from "../../assets/images/team_members/member-08.png"
import TeamMember9 from "../../assets/images/team_members/member-09.png"

const teamMembers = [
  { imgSrc: TeamMember1, name: "Anderson Elizabeth", role: "Marketing Manager" },
  { imgSrc: TeamMember2, name: "Anderson Elizabeth", role: "Marketing Manager" },
  { imgSrc: TeamMember3, name: "Anderson Elizabeth", role: "Marketing Manager" },
  { imgSrc: TeamMember4, name: "Anderson Elizabeth", role: "Marketing Manager" },
  { imgSrc: TeamMember5, name: "Anderson Elizabeth", role: "Marketing Manager" },
  { imgSrc: TeamMember6, name: "Anderson Elizabeth", role: "Marketing Manager" },
  { imgSrc: TeamMember7, name: "Anderson Elizabeth", role: "Marketing Manager" },
  { imgSrc: TeamMember8, name: "Anderson Elizabeth", role: "Marketing Manager" },
  { imgSrc: TeamMember9, name: "Anderson Elizabeth", role: "Marketing Manager" }
];

function TeamMember() {
  return (
    <div>
      <section id="team-members" className="team-members-main-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section text-center">
                <h6 className="section-sub-heading">Team members</h6>
                <h3 className="section-heading mb_60">
                  Our Dedicated Team
                  <br />
                  Members
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            {teamMembers.map((member, index) => (
              <div className="col-lg-4" key={index}>
                <div className="member-block">
                  <div className="member-img">
                    <Link to="/team_detail" title="">
                      <img src={member.imgSrc} className="img-fluid" alt="" />
                    </Link>
                  </div>
                  <div className="team-member-dtls text-center">
                    <Link to="/team_detail">
                      <h6 className="member-heading">{member.name}</h6>
                    </Link>
                    <Link to="#">
                      <p>{member.role}</p>
                    </Link>
                    <div className="member-social">
                      <ul>
                        <li>
                          <Link to="#">
                            <i className="flaticon-facebook-app-symbol"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="flaticon-twitter-1"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="flaticon-linkedin"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="flaticon-pinterest"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li className="page-item">
                <Link className="page-link arrow" to="#" aria-label="Previous">
                  <i className="flaticon-back"></i>
                </Link>
              </li>
              <li className="page-item active">
                <Link className="page-link" to="#">
                  1
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  2
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  3
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  4
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link arrow" to="#" aria-label="Next">
                  <i className="flaticon-double-arrow"></i>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </section>
    </div>

  )
}

export default TeamMember;
