import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import videoBgImg3 from '../../assets/images/bg/video-bg-3.jpg';

function VdoThree() {
  const [showPlayer, setShowPlayer] = useState(false);

  const togglePlayer = () => {
    setShowPlayer(!showPlayer);
  };

  return (
    <div>
      <div id="video" className="video-main-block-3 parallax">
        <div className="video-dtls-block-3">
          <img src={videoBgImg3} className="img-fluid" alt="" />
        </div>
        <div className="btn play-btn-3 pointer-zoom vertical-center">
          <Link
            to="#"
            title=""
            onClick={togglePlayer}
          >
            Play
          </Link>
        </div>
        <div
          className={showPlayer ? 'modal d-block' : 'modal fade'}
          id="videomodal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={togglePlayer}
                ></button>
              </div>
              <div className="modal-body">
                <iframe
                  width="650"
                  height="400"
                  title="ifram5"
                  src="https://www.youtube.com/embed/videoseries?si=VW5WfnzCjbpDnW57&amp;list=PLs9JJVQP1gn2SVodyu-ydHCnfY_ZgMocO"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VdoThree;
