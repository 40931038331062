import React from 'react'
import Slider from "react-slick";
import CountUp from 'react-countup';


import clientImg7 from "../../assets/images/testimonials/client-07.png";

const Testfour = {
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: false,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

function Testimonialfour() {
    return (
        <div>
            <section id="testimonials" className="testimonials-main-block-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-12 col-12">
                            <div className="section">
                                <h6 className="section-sub-heading">
                                    <i className="flaticon-dot dot-icon"></i>Testimonials
                                </h6>
                                <h3 className="section-heading">
                                    Latest feedback
                                    <br />
                                    From Our Client
                                </h3>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-12">
                            <div className="sub-section-2 float-end">
                                <h3 className="section-heading">
                                    <CountUp end={30} duration={5} />K+
                                </h3>
                                <p>Unlock your Success Today! </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Slider className="testimonials-slider-4" {...Testfour}>
                            <div className="testimonials-block-4">
                                <div className="testimonials-img-4">
                                    <img
                                        src={clientImg7}
                                        className="img-fluid"
                                        alt=""
                                    />
                                </div>
                                <div className="client-reviw-block-4">
                                    <div className="client-review-4">
                                        <p>
                                            <em>
                                                I couldn't have asked for a better real estate agency
                                                to sell my property. brought in buyers, negotiated a
                                                great deal.
                                            </em>
                                        </p>
                                        <div className="client-ratings">
                                            <ul>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                            </ul>
                                        </div>
                                        <h5 className="client-name">Andrew Mantos</h5>
                                        <div className="client-location">Vatican City</div>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonials-block-4">
                                <div className="testimonials-img-4">
                                    <img
                                        src={clientImg7}
                                        className="img-fluid"
                                        alt=""
                                    />
                                </div>
                                <div className="client-reviw-block-4">
                                    <div className="client-review-4">
                                        <p>
                                            <em>
                                                ,I couldn't have asked for a better real estate agency
                                                to sell my property. brought in buyers, negotiated a
                                                great deal.
                                            </em>
                                        </p>
                                        <div className="client-ratings">
                                            <ul>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                            </ul>
                                        </div>
                                        <h5 className="client-name">Andrew Mantos</h5>
                                        <div className="client-location">Vatican City</div>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonials-block-4">
                                <div className="testimonials-img-4">
                                    <img
                                        src={clientImg7}
                                        className="img-fluid"
                                        alt=""
                                    />
                                </div>
                                <div className="client-reviw-block-4">
                                    <div className="client-review-4">
                                        <p>
                                            <em>
                                                ,I couldn't have asked for a better real estate agency
                                                to sell my property. brought in buyers, negotiated a
                                                great deal.
                                            </em>
                                        </p>
                                        <div className="client-ratings">
                                            <ul>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                            </ul>
                                        </div>
                                        <h5 className="client-name">Andrew Mantos</h5>
                                        <div className="client-location">Vatican City</div>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonials-block-4">
                                <div className="testimonials-img-4">
                                    <img
                                        src={clientImg7}
                                        className="img-fluid"
                                        alt=""
                                    />
                                </div>
                                <div className="client-reviw-block-4">
                                    <div className="client-review-4">
                                        <p>
                                            <em>
                                                ,I couldn't have asked for a better real estate agency
                                                to sell my property. brought in buyers, negotiated a
                                                great deal.
                                            </em>
                                        </p>
                                        <div className="client-ratings">
                                            <ul>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                                <li>
                                                    <i className="flaticon-star"></i>
                                                </li>
                                            </ul>
                                        </div>
                                        <h5 className="client-name">Andrew Mantos</h5>
                                        <div className="client-location">Vatican City</div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Testimonialfour