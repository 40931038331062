import React from 'react';
import { Link } from 'react-router-dom';
import PostImg5 from "../../assets/images/post/post-05.jpg";
import PostImg6 from "../../assets/images/post/post-06.jpg";
import PostImg7 from "../../assets/images/post/post-07.jpg";

function Blogthree() {
    const posts = [
        {
            id: 1,
            imgSrc: PostImg5,
            day: "18",
            month: "Nov",
            category: "Apartment News",
            title: "We are creating Your Dream House that are both",
        },
        {
            id: 2,
            imgSrc: PostImg6,
            day: "20",
            month: "Nov",
            category: "Villa Sell Program",
            title: "Villa Apartment Selling Program News",
        },
        {
            id: 3,
            imgSrc: PostImg7,
            day: "24",
            month: "Nov",
            category: "Properties Selling Program",
            title: "We are creating Your Dream House that are both",
        },
    ];

    return (
        <div>
            <section id="blog" className="blog-main-block-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="section svg-border-img">
                                <h6 className="section-sub-heading">Blog &amp; News</h6>
                                <h3 className="section-heading mb_60">
                                    Release Our Latest News &amp; Blogs
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {posts.map(post => (
                            <div className="col-lg-4 col-md-4" key={post.id}>
                                <div className="post-block-3">
                                    <div className="post-img-3 mb_20">
                                        <Link to="blog_details" title="">
                                            <img
                                                src={post.imgSrc}
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </Link>
                                    </div>
                                    <div className="post-date-3">
                                        <div className="post-dt">
                                            <Link to="#" title="">
                                                {post.day}
                                            </Link>
                                        </div>
                                        <div className="post-month">
                                            <Link to="#" title="">
                                                {post.month}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="post-dtls">
                                        <ul>
                                            <li>
                                                <Link to="#" title="">
                                                    {post.category}
                                                </Link>
                                            </li>
                                        </ul>
                                        <Link to="#" title=""></Link>
                                    </div>
                                    <h6 className="post-title">
                                        <Link to="blog_details" title="">
                                            {post.title}
                                        </Link>
                                    </h6>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Blogthree;
