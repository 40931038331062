import React from "react";
import HeaderThree from '../HeaderThree/Main'
import SliderThree from "../MenhomeThree/SliderThree";
import Find from "../MenhomeThree/Find";
import Abtone from "../Menhomeone/Abtone";
import ServiceThree from "../MenhomeThree/ServiceThree";
import PropertiesThree from '../MenhomeThree/PropertiesThree'
import VdoThree from "../MenhomeThree/VdoThree";
import FactsThree from "../MenhomeThree/FactsThree";
import TeamThree from "../MenhomeThree/TeamThree";
import LatestProjectThree from "../MenhomeThree/LatestProjectThree";
import TestimonialThree from "../MenhomeThree/TestimonialThree";
import Blogthree from "../MenhomeThree/Blogthree";
import Newslatter from "../Menhomeone/Newsletter";
import FooterOne from '../FooterOne/Main';

function Main() {
  return (
    <div>
      <HeaderThree />
      <SliderThree />
      <Find />
      <Abtone />
      <ServiceThree />
      <PropertiesThree />
      <VdoThree />
      <FactsThree />
      <TeamThree />
      <LatestProjectThree />
      <TestimonialThree />
      <Blogthree />
      <Newslatter />
      <FooterOne />
    </div>
  );
}

export default Main;
