import React from 'react'
import NewsSuscImg from "../../assets/images/bg/newsletter-bg.png";


function NewslatterTwo() {
    return (
        <div>
            <section id="newsletter" className="newsletter-main-block-2">
                <div className="container">
                    <div
                        className="row newsletter-main-block-bg"
                        style={{
                            backgroundImage: `url(${NewsSuscImg})`,
                        }}
                    >
                        <div className="offset-lg-1 col-lg-5">
                            <div className="section">
                                <h3 className="section-heading">
                                    Real Estate Success Update To Know
                                </h3>
                                <p>
                                    Real estate transactions are not just about properties they
                                    are about people and their dreams
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="newsletter-form vertical-center">
                                <form>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="subscribe"
                                        aria-describedby="subscribe"
                                        placeholder="Enter Your Mail"
                                    />
                                    <button
                                        type="submit"
                                        className="btn pointer-zoom btn-primary"
                                    >
                                        Subscribe Now
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default NewslatterTwo