import React from 'react';
import { Link } from 'react-router-dom';
import ProjectImg10 from '../../assets/images/projects/projects-10.png';
import ProjectImg11 from '../../assets/images/projects/projects-11.png';
import ProjectImg12 from '../../assets/images/projects/projects-12.png';
import ProjectImg13 from '../../assets/images/projects/projects-13.png';
import ProjectImg14 from '../../assets/images/projects/projects-14.png';
import ProjectImg15 from '../../assets/images/projects/projects-15.png';

function LatestProjectThree() {
    const projects = [
        { img: ProjectImg10, name: 'Apartments', numProperties: '18 Properties' },
        { img: ProjectImg13, name: 'Villa Apartments', numProperties: '17 Properties' },
        { img: ProjectImg11, name: 'Duplex Apartments', numProperties: '15 Properties' },
        { img: ProjectImg14, name: 'Office Space', numProperties: '24 Properties' },
        { img: ProjectImg12, name: 'Townhouse', numProperties: '20 Properties' },
        { img: ProjectImg15, name: 'Shopping Mall', numProperties: '10 Properties' },
    ];

    return (
        <div>
            <section id="latest-projects" className="projects-main-block-3">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section text-center svg-border-img">
                                <h6 className="section-sub-heading">Project</h6>
                                <h3 className="section-heading mb_60">Explore Our Latest Project</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="masonry-grid">
                                {projects.map((project, index) => (
                                    <div className="masonry-grid-item" key={index}>
                                        <Link to="#" title="">
                                            <img src={project.img} className="img-fluid masonry-img" alt="" />
                                        </Link>
                                        <div className="overlay-bg-2"></div>
                                        <div className="projects-3-dtls">
                                            <Link to="#" title="">
                                                <h6 className="project-3-heading">{project.name}</h6>
                                            </Link>
                                            <Link to="#" title="" className="project-num">
                                                {project.numProperties}
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default LatestProjectThree;
