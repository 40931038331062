import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogoImg from "../../assets/images/general/logo.png";

function Main() {

  const [homeDropDown, setHomeDropDown] = useState(false);
  const [serviceDropDown, setServiceDropDown] = useState(false);
  const [pageDropDown, setPageDropDown] = useState(false);
  const [navShow, setNavShow] = useState(false);

  const toggleMobileMenu = () => {
    setNavShow(!navShow);
  };

  const toggleHomeDropDown = () => {
    setHomeDropDown(!homeDropDown);
    setServiceDropDown(false);
    setPageDropDown(false);
  };

  const toggleServiceDropDown = () => {
    setServiceDropDown(!serviceDropDown);
    setHomeDropDown(false);
    setPageDropDown(false);
  };

  const togglePageDropDown = () => {
    setPageDropDown(!pageDropDown);
    setHomeDropDown(false);
    setServiceDropDown(false);
  };

  return (
    <div id="top-menu" className="top-menu-main-block">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 text-start col-6">
            <div className="rexain-logo">
              <Link to="/" title="">
                <img src={LogoImg} alt="" className="img-fluid" />
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-6">
            <div className="navigation">
              <nav className="navbar navbar-expand-lg">
                <button className="navbar-toggler" data-bs-toggle="collapse"
                  aria-controls="navbarSupportedContent" type="button" aria-expanded="false"
                  onClick={toggleMobileMenu} aria-label="Toggle navigation" >
                  <span>
                    <i className="flaticon-menu"></i>
                  </span>
                </button>
                <div
                  className={
                    navShow
                      ? "collapse navbar-collapse show"
                      : "collapse navbar-collapse"
                  }
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item dropdown active">
                      <Link className="nav-link pointer-zoom dropdown-toggle"
                        to="/" role="button"
                        data-bs-toggle="dropdown"
                        onClick={toggleHomeDropDown}
                        aria-expanded={homeDropDown ? "true" : "false"}

                      >
                        Anasayfa 
                        {/* <i className="flaticon-down-chevron"></i> */}
                      </Link>
                      {/* <ul
                        className={
                          homeDropDown ? "dropdown-menu show" : "dropdown-menu"
                        }
                      >
                        <li>
                          <Link
                            className="dropdown-item pointer-zoom pointer-zoom active"
                            to="/"
                          >
                            Anasayfa 1
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item pointer-zoom"
                            to="/hometwo"
                          >
                            Anasayfa 2
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item pointer-zoom"
                            to="/homethree"
                          >
                            Anasayfa 3
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item pointer-zoom"
                            to="/homefour"
                          >
                            Anasayfa 4
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link pointer-zoom dropdown-toggle"
                        to="/about"
                      >
                        Hakkımızda
                      </Link>
                    </li>
                    {/* <li className="nav-item dropdown">
                      <Link
                        className="nav-link pointer-zoom dropdown-toggle"
                        to="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        onClick={toggleServiceDropDown}
                        aria-expanded={serviceDropDown ? "true" : "false"}
                      >
                        Service<i className="flaticon-down-chevron"></i>
                      </Link>
                      <ul
                        className={
                          serviceDropDown
                            ? "dropdown-menu show"
                            : "dropdown-menu"
                        }
                      >
                        <li>
                          <Link
                            className="dropdown-item pointer-zoom"
                            to="/service"
                          >
                            Services
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item pointer-zoom"
                            to="/service_details"
                          >
                            Service details
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                     <li className="nav-item pointer-zoom dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="/team_detail"
                        role="button"
                        data-bs-toggle="dropdown"
                        onClick={togglePageDropDown}
                        aria-expanded={pageDropDown ? "true" : "false"}
                      >
                        Duyurular
                        {/* <i className="flaticon-down-chevron"></i> */}
                      </Link>
                      {/* <ul
                        className={
                          pageDropDown ? "dropdown-menu show" : "dropdown-menu"
                        }
                      >
                        <li>
                          <Link
                            className="dropdown-item pointer-zoom"
                            to="/team_members"
                          >
                            Teams
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item pointer-zoom"
                            to="/team_detail"
                          >
                            Team Details
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item pointer-zoom"
                            to="/blog_single"
                          >
                            Blog
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item pointer-zoom"
                            to="/blog_details"
                          >
                            Blog Details
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item pointer-zoom"
                            to="/error_page"
                          >
                            404 Page
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link pointer-zoom" to="/contact_us">
                        İletişim
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <div className="col-lg-3 text-end col-12">
            <div className="top-menu-social">
              <ul>
                <li>
                  <Link to="https://www.facebook.com/" title="">
                    <i className="flaticon-facebook-app-symbol"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://twitter.com/login" title="">
                    <i className="flaticon-twitter-1"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://in.linkedin.com/">
                    <i className="flaticon-linkedin"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://in.pinterest.com/" title="">
                    <i className="flaticon-pinterest"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/" title="">
                    <i className="flaticon-instagram"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
