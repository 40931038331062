import React, { useState } from "react";
import BgVideoImg from "../../assets/images/bg/video-bg-2.png";
import { Link } from 'react-router-dom';


function Videotwo() {

    const [showPlayer, setshowPlayer] = useState(false);


    return (
        <div>
            <section
                id="video"
                className="video-main-block-2 parallax"
                style={{ backgroundImage: `url(${BgVideoImg})` }}
            >
                <div className="overlay-bg" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-md-1">
                            <div className="video-dtls text-center vertical-center">
                                <h2 className="video-headings">Watch Latest Apartment View</h2>
                                <div className="play-btn-2">
                                    <Link
                                        to="#"
                                        title=""
                                        data-bs-toggle="modal"
                                        data-bs-target="#videomodal"
                                        onClick={() => setshowPlayer(!showPlayer)}
                                    >
                                        <i className="flaticon-play-button-arrowhead" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div
                            className={showPlayer ? "modal d-block" : "modal fade"}
                            id="videomodal"
                            tabIndex={-1}
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            onClick={() => setshowPlayer(!showPlayer)}
                                            aria-label="Close"
                                        />
                                    </div>
                                    <div className="modal-body">
                                        <iframe
                                            width={650}
                                            height={400}
                                            src="https://www.youtube.com/embed/videoseries?si=VW5WfnzCjbpDnW57&list=PLs9JJVQP1gn2SVodyu-ydHCnfY_ZgMocO"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Videotwo