import React from "react";
import { Link } from "react-router-dom";
import FooterLogo4 from "../../assets/images/bg/footer-bg-4.png"

function Main() {
  return (
    <footer
      className="footer-main-block-4"
      style={{ backgroundImage: `url(${FooterLogo4})` }}
    >
      <div className="footer-block-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footer-contact-4">
                <h5 className="footer-contact-heading">Contact</h5>
                <ul>
                  <li>
                    <Link to="tel:" title="">
                      <i className="flaticon-call-1"></i>+99-890-9809
                    </Link>
                  </li>
                  <li>
                    <Link to="mailto:" title="">
                      <i className="flaticon-email-1"></i>marketing@realco.com
                    </Link>
                  </li>
                  <li>
                    <Link to="#" title="">
                      <i className="flaticon-location"></i>Lorel Street, City
                      Tower,London, UK
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="footer-dtls-4">
                <h2 className="footer-4-heading text-center">Rexain</h2>
                <p className="text-center">
                  Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the dummy text dummy
                </p>
                <div className="footer-social-4 text-center">
                  <ul>
                    <li>
                      <Link to="https://www.facebook.com/" target="_blank" title="">
                        <i className="flaticon-facebook-app-symbol"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://twitter.com/login" target="_blank" title="">
                        <i className="flaticon-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://in.linkedin.com/" target="_blank" title="">
                        <i className="flaticon-linkedin"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://in.pinterest.com/" target="_blank" title="">
                        <i className="flaticon-pinterest"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.instagram.com/" target="_blank" title="">
                        <i className="flaticon-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="footer-links-4">
                <h5 className="footer-link-heading">Links</h5>
                <ul>
                  <li>
                    <Link to="/about" title="">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" title="">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="#" title="">
                      Pages
                    </Link>
                  </li>
                  <li>
                    <Link to="/service_details" title="">
                      Properties
                    </Link>
                  </li>
                  <li>
                    <Link to="#" title="">
                      Testimonials
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact_us" title="">
                      Contacts
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="horizontal-line-2"></div>
        </div>
      </div>
      <div className="tiny-footer-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="copyright">Copyright © 2024 Alright Reserved</div>
            </div>
            <div className="col-lg-6">
              <div className="policy text-end">
                <Link to="#" title="">
                  Terms & Conditions / Privacy & Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Main;
