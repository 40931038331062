import React, { useState } from "react";
import ServiceImg4 from "../../assets/images/services/services-04.png";

function ServiceTwo() {
    const [activeSection, setActiveSection] = useState("flush-headingOne");

    const handleToggleSection = (sectionIndex) => {
        if (activeSection === sectionIndex) {
          setActiveSection(null);
        } else {
          setActiveSection(sectionIndex);
        }
      };

    const accordionItems = [
        {
            id: "flush-headingOne",
            question: "Luxury Apartment",
            content: "They are about people and their dreams. That's why we take the time to listen to your unique needs and aspirations That's why we take aspirations in the world wide services.",
        },
        {
            id: "flush-headingTwo",
            question: "Architecture Design",
            content: "They are about people and their dreams. That's why we take the time to listen to your unique needs and aspirations That's why we take aspirations in the world wide services.",
        },
        {
            id: "flush-headingThree",
            question: "Extra strong security",
            content: "They are about people and their dreams. That's why we take the time to listen to your unique needs and aspirations That's why we take aspirations in the world wide services.",
        },
        {
            id: "flush-headingFour",
            question: "HOME REMODELING",
            content: "They are about people and their dreams. That's why we take the time to listen to your unique needs and aspirations That's why we take aspirations in the world wide services.",
        },
    ];

    return (
        <div>
            <section id="services" className="services-main-block-2">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="section text-center">
                                <h6 className="section-sub-heading">Services</h6>
                                <h3 className="section-heading mb_60">What We Offer For You</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row g-0 services-dtls-row">
                        <div className="col-lg-6">
                            <div className="services-block-2">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    {accordionItems.map(({ id, question, content }, index) => (
                                        <div className="accordion-item" key={id}>
                                            <h2 className="accordion-header" id={id}>
                                                <button className={`accordion-button  ${activeSection === id ? 'active' : 'collapsed'}`} type="button" onClick={() => handleToggleSection(id)}>
                                                    {question}
                                                </button>
                                            </h2>
                                            <div id={`flush-collapseOne-${id}`} className={`accordion-collapse collapse  ${activeSection === id ? 'show' : 'collapse'}`} aria-labelledby={id} data-bs-parent="#accordionFlushExample" >
                                                <div className="accordion-body">
                                                    {content}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="services-img-2">
                                <div className="services-house-img">
                                    <div className="hover-img">
                                        <div className="hover-action" data-scale="1.15" data-image={ServiceImg4}>
                                            <div className="moving-image" style={{ backgroundImage: `url(${ServiceImg4})` }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ServiceTwo;
