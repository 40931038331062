import React from 'react'

function Newslatter() {
    return (
        <div>
            <section id="newsletter" className="newsletter-main-block">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-1 col-lg-5">
                            <div className="section">
                                <h3 className="section-heading">
                                    Haberdar olun...
                                </h3>
                                <p>
                                    Bültenimize abone olarak gelişmelerden
                                    <br />
                                    haberdar olabilirsiniz...
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="newsletter-form vertical-center">
                                <form>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="subscribe"
                                        aria-describedby="subscribe"
                                        placeholder="Mail Adresinizi Giriniz..."
                                    />
                                    <button
                                        type="submit"
                                        className="btn pointer-zoom btn-primary"
                                    >
                                        Şimdi Abone Ol
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Newslatter