import React from 'react';
import { Link } from "react-router-dom";
import PostImg2 from "../../assets/images/post/post-02.png";
import PostImg3 from "../../assets/images/post/post-03.png";
import PostImg4 from "../../assets/images/post/post-04.png";
import PostSingleImg1 from "../../assets/images/blog_single/blog-01.png";
import PostSingleImg2 from "../../assets/images/blog_single/blog-02.png";
import PostSingleImg3 from "../../assets/images/blog_single/blog-03.png";
import PostSingleImg4 from "../../assets/images/blog_single/blog-04.png";


const postImages = [PostImg2, PostImg3, PostImg4, PostImg2, PostImg3, PostImg4];
const postSingleImages = [PostSingleImg1, PostSingleImg2, PostSingleImg3, PostSingleImg4];

function Blog() {
  return (
    <div>
      <section id="single-blog" className="single-blog-main-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blogs">
                <div className="row">
                  {postImages.map((img, index) => (
                    <div key={index} className="col-lg-6">
                      <div className="our-blog mb_40">
                        <div className="post-block-2">
                          <div className="post-img">
                            <Link to="/blog_details" title="">
                              <img src={img} className="img-fluid" alt="" />
                            </Link>
                            <div className="post-user">
                              <Link
                                to="#"
                                className="btn pointer-zoom btn-outline-primary"
                                title=""
                              >
                                <i className="flaticon-user" />
                                Admin
                              </Link>
                            </div>
                          </div>
                          <div className="post-dtls">
                            <ul>
                              <li>
                                <Link to="#" title="">
                                  Design Structure
                                </Link>
                              </li>
                              <li>/</li>
                              <li>
                                <Link to="#" title="">
                                  June 10, 2024
                                </Link>
                              </li>
                            </ul>
                            <Link to="#" title="" />
                          </div>
                          <div className="horizontal-line" />
                          <h6 className="post-title">
                            <Link to="/blog_details" title="">
                              We are creating Your Dream House that are both
                            </Link>
                          </h6>
                          <Link
                            to="#"
                            className="btn pointer-zoom btn-outline-primary"
                            title=""
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <div className="search-bar mb_40">
                <form className="d-flex" role="search">
                  <input
                    className="form-control me-2"
                    type="search"
                    id="search"
                    placeholder="Search Here . . ."
                    aria-label="Search"
                  />
                  <button className="btn" type="submit">
                    <i className="flaticon-search" />
                  </button>
                </form>
              </div>
              <div className="blog-categories mb_40">
                <h6 className="blog-categories-heading">Categories</h6>
                <div className="horizontal-line-3 mb_30" />
                <div className="category-link">
                  <div className="blog-links mb_20">
                    <Link to="#" title="">
                      Real Estate <span>(02)</span>
                    </Link>
                  </div>
                  <div className="horizontal-line mb_20" />
                  <div className="blog-links mb_20">
                    <Link to="#" title="">
                      {" "}
                      Apartment <span>(08)</span>
                    </Link>
                  </div>
                  <div className="horizontal-line mb_20" />
                  <div className="blog-links mb_20">
                    <Link to="#" title="">
                      House Rent <span>(05)</span>
                    </Link>
                  </div>
                  <div className="horizontal-line mb_20" />
                  <div className="blog-links mb_20">
                    <Link to="#" title="">
                      Architecture <span>(02)</span>
                    </Link>
                  </div>
                  <div className="horizontal-line mb_20" />
                  <div className="blog-links mb_20">
                    <Link to="#" title="">
                      {" "}
                      Villa Apartment <span>(11)</span>
                    </Link>
                  </div>
                  <div className="horizontal-line mb_20" />
                  <div className="blog-links mb_20">
                    <Link to="#" title="">
                      Agent <span>(12)</span>
                    </Link>
                  </div>
                  <div className="horizontal-line mb_20" />
                </div>
              </div>
              <div className="recent-post mb_40">
                <h6 className="recent-post-heading">Recent Post</h6>
                <div className="horizontal-line-3 mb_30" />
                {postSingleImages.map((img, index) => (
                  <div key={index} className="recent-post-block mb_20">
                    <div className="row">
                      <div className="col-lg-4">
                        <Link to="/blog_details" title="">
                          <img src={img} className="img-fluid" alt="" />
                        </Link>
                      </div>
                      <div className="col-lg-8">
                        <div className="recent-post-lst">
                          <i className="flaticon-calendar" />
                          <span>22 Oct, 2024</span>
                          <p>
                            <Link to="/blog_details" title="">
                              Indeed base to a must be in a month
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="blog-tags">
                <h6 className="recent-post-heading">Tags</h6>
                <div className="horizontal-line-3 mb_30" />
                <Link to="#" className="btn tag-btn" title="">
                  Real Estate
                </Link>
                <Link to="#" className="btn tag-btn" title="">
                  Architecture
                </Link>
                <Link to="#" className="btn tag-btn" title="">
                  Villa
                </Link>
                <Link to="#" className="btn tag-btn" title="">
                  Agent
                </Link>
                <Link to="#" className="btn tag-btn" title="">
                  Services
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Blog
