import React from "react";
import { Link } from "react-router-dom";
import FooterImg3 from "../../assets/images/bg/footer-bg.png";
import FooterImg4 from "../../assets/images/general/footer-logo.png";

function Main() {
  return (
    <footer className="footer-main-block-2">
      <div
        className="footer-block-2"
        style={{ backgroundImage: `url(${FooterImg3})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="footer-dtls">
                <div className="footer-logo">
                  <Link to="/" title="">
                    <img src={FooterImg4} className="img-fluid" alt="" />
                  </Link>
                </div>
                <p>
                  Real estate transactions are not just about properties they
                  are about people and their dreams
                </p>
                <ul className="footer-social">
                  <li>
                    <Link to="https://www.facebook.com/" target="_blank" title="">
                      <i className="flaticon-facebook-app-symbol"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://twitter.com/login" target="_blank" title="">
                      <i className="flaticon-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://in.linkedin.com/" target="_blank" title="">
                      <i className="flaticon-linkedin"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://in.pinterest.com/" target="_blank" title="">
                      <i className="flaticon-pinterest"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/" target="_blank" title="">
                      <i className="flaticon-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="widget-menu">
                <h5 className="widget-heading">Quick Links</h5>
                <ul>
                  <li>
                    <Link to="/about" title="">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" title="">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="#" title="">
                      Pages
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" title="">
                      Properties
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact_us" title="">
                      Contacts
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="widget-menu">
                <h5 className="widget-heading">Pages</h5>
                <ul>
                  <li>
                    <Link to="/about" title="">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" title="">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="#" title="">
                      Pages
                    </Link>
                  </li>
                  <li>
                    <Link to="#" title="">
                      Properties
                    </Link>
                  </li>
                  <li>
                    <Link to="#" title="">
                      Faqs
                    </Link>
                  </li>
                  <li>
                    <Link to="#" title="">
                      Testimonials
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact_us" title="">
                      Contacts
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="widget-contact">
                <h2 className="widget-heading">Get in Touch</h2>
                <ul className="info">
                  <li>
                    <i className="flaticon-call-1"></i>
                    <Link to="tel:" title="">
                      997-889-0987
                    </Link>
                  </li>
                  <li>
                    <i className="flaticon-email-1"></i>
                    <Link to="mailto:" title="">
                      support@example.com
                    </Link>
                  </li>
                  <li>
                    <i className="flaticon-location"></i>Alison
                    Street,10090,Canada
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tiny-footer-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="copyright">
                Copyright &copy; 2024 Alright Reserved
              </div>
            </div>
            <div className="col-lg-6">
              <div className="policy">
                <Link to="#" title="">
                  Terms &amp; Conditions / Privacy &amp; Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Main;
