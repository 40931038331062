import React from "react";
import HeaderFour from '../HeaderFour/Main'
import Sliderfour from "../Menhomefour/Sliderfour";
import Partnersblock from "../Menhomefour/Partnersblock";
import Abtfour from "../Menhomefour/Abtfour";
import Serviceblock from "../Menhomefour/Serviceblock";
import Propertyfour from "../Menhomefour/Propertyfour";
import Latestprojectfour from "../Menhomefour/Latestprojectfour";
import Teamfour from "../Menhomefour/Teamfour";
import Vdofour from "../Menhomefour/Vdofour";
import Testimonialfour from "../Menhomefour/Testimonialfour";
import Blogfour from "../Menhomefour/Blogfour";
import FooterThree from '../FooterThree/Main'

function Main() {
  return (
    <div>
      <HeaderFour />
      <Sliderfour />
      <Partnersblock />
      <Abtfour />
      <Serviceblock />
      <Propertyfour/>
      <Latestprojectfour />
      <Teamfour />
      <Vdofour />
      <Testimonialfour />
      <Blogfour />
      <FooterThree />
    </div>
  );
}

export default Main;
