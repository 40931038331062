import React, { useState } from "react";
import { Link } from "react-router-dom";
import ImgLogo from "../../assets/images/general/logo.png";

function Main() {
  const [homeDropDown, sethomeDropDown] = useState(false);
  const [serviceDropDown, setserviceDropDown] = useState(false);
  const [pageDropDown, setpageDropDown] = useState(false);
  const [navShow, setnavShow] = useState(false);

  const toggleMobileMenu = () => {
    setnavShow(!navShow);
  };
  return (
    <div id="top-menu" className="top-menu-main-block-4">
      <div className="container-fluid">
        <nav className="navbar">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img
                src={ImgLogo}
                className="img-fluid"
                alt=""
              />
            </Link>
            <button
              className="navbar-toggler pointer-zoom menu-btn"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              onClick={toggleMobileMenu}
            >
              Menu<i className="flaticon-menu"></i>
            </button>
            <div
              className={
                navShow
                  ? "offcanvas offcanvas-end show"
                  : "offcanvas offcanvas-end"
              }
              tabIndex="-1"
              id="offcanvasNavbar"
            >
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                onClick={toggleMobileMenu}
                aria-label="Close"
              ></button>
              <div className="offcanvas-body p-4">
                <ul className="navbar-nav justify-content-end text-start flex-grow-1 mb-3">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle active pointer-zoom"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      onClick={() => sethomeDropDown(!homeDropDown)}
                      aria-expanded="false"
                    >
                      Home<i className="flaticon-down-chevron"></i>
                    </Link>
                    <ul
                      className={
                        homeDropDown ? "dropdown-menu show" : "dropdown-menu"
                      }
                    >
                      <li>
                        <Link className="dropdown-item" to="/">
                          Home 1
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/hometwo">
                          Home 2
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/homethree">
                          Home 3
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item active" to="/homefour">
                          Home 4
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link pointer-zoom dropdown-toggle"
                      to="/about"
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle pointer-zoom"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => setserviceDropDown(!serviceDropDown)}
                    >
                      Service<i className="flaticon-down-chevron"></i>
                    </Link>
                    <ul
                      className={
                        serviceDropDown ? "dropdown-menu show" : "dropdown-menu"
                      }
                    >
                      <li>
                        <Link
                          className="dropdown-item pointer-zoom"
                          to="/service"
                        >
                          Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item pointer-zoom"
                          to="/service_details"
                        >
                          Service details
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle pointer-zoom"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => setpageDropDown(!pageDropDown)}
                    >
                      Page <i className="flaticon-down-chevron"></i>
                    </Link>
                    <ul
                      className={
                        pageDropDown ? "dropdown-menu show" : "dropdown-menu"
                      }
                    >
                      <li>
                        <Link
                          className="dropdown-item pointer-zoom"
                          to="/team_members"
                        >
                          Teams
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item pointer-zoom"
                          to="/team_detail"
                        >
                          Team Detail
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item pointer-zoom"
                          to="/blog_single"
                        >
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item pointer-zoom"
                          to="/blog_details"
                        >
                          Blog Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item pointer-zoom"
                          to="/error_page"
                        >
                          404 Page
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item pointer-zoom">
                    <Link
                      className="nav-link"
                      aria-current="page"
                      to="/contact_us"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
                <ul className="text-start mb-4">
                  <li className="mb-4">
                    <i className="flaticon-location me-2"></i>Alison
                    Street,10090,Canada
                  </li>
                  <li>
                    <i className="flaticon-call-1 me-2"></i>
                    <Link to="tel:" title="">
                      997-889-0987
                    </Link>
                  </li>
                </ul>
                <div className="top-menu-social-3 text-start">
                  <Link to="https://www.facebook.com/" title="">
                    <i className="flaticon-facebook-app-symbol"></i>
                  </Link>
                  <Link to="https://twitter.com/login" title="">
                    <i className="flaticon-twitter-1"></i>
                  </Link>
                  <Link to="https://www.instagram.com/" title="">
                    <i className="flaticon-instagram"></i>
                  </Link>
                  <Link to="https://in.linkedin.com/" title="">
                    <i className="flaticon-linkedin"></i>
                  </Link>
                  <Link to="https://in.pinterest.com/" title="">
                    <i className="flaticon-pinterest"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Main;
