import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import TeamMemberImg1 from '../../assets/images/teams/member-01.png';
import TeamMemberImg2 from '../../assets/images/teams/member-02.png';
import TeamMemberImg3 from '../../assets/images/teams/member-03.png';
import TeamMemberImg4 from '../../assets/images/teams/member-04.png';

const teamMembers = [
    { imgSrc: TeamMemberImg1, name: 'Anderson Elizabeth', position: 'Marketing Manager' },
    { imgSrc: TeamMemberImg2, name: 'Anderson Elizabeth', position: 'Marketing Manager' },
    { imgSrc: TeamMemberImg3, name: 'Anderson Elizabeth', position: 'Marketing Manager' },
    { imgSrc: TeamMemberImg4, name: 'Anderson Elizabeth', position: 'Marketing Manager' },
];

const TeamTwoSlider = {
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};

function Teamtwo() {
    return (
        <div>
            <section id="teams" className="teams-main-block">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section">
                                <h6 className="section-sub-heading">Expert team</h6>
                                <h3 className="section-heading mb_60">
                                    Meet With Our Dedicated
                                    <br />
                                    Team Members
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Slider className="teams-slider" {...TeamTwoSlider}>
                            {teamMembers.map((member, index) => (
                                <div className="teams-block" key={index}>
                                    <div className="member-img">
                                        <Link to="/team_detail" title="">
                                            <img src={member.imgSrc} className="img-fluid" alt="" />
                                        </Link>
                                    </div>
                                    <div className="member-dtls text-center">
                                        <h6 className="member-name">
                                            <Link to="/team_detail" title="">
                                                {member.name}
                                            </Link>
                                        </h6>
                                        <div className="member-position">{member.position}</div>
                                        <ul className="member-social">
                                            <li>
                                                <Link to="#" title="">
                                                    <i className="flaticon-facebook-app-symbol"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#" title="">
                                                    <i className="flaticon-twitter"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#" title="">
                                                    <i className="flaticon-linkedin"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#" title="">
                                                    <i className="flaticon-pinterest"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#" title="">
                                                    <i className="flaticon-instagram"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Teamtwo;
