import React from 'react'
import Slider from "react-slick";
import TestmoniImg8 from "../../assets/images/testimonials/testimonials-08.png";
import TestmoniImgPng from "../../assets/images/testimonials/testimonials.png";

const testimonialtwoslider = {
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: false,
    centerPadding: "180px",
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};

function Testimonialtwo() {
    return (
        <div>
            <section id="testimonials" className="testimonials-main-block-2">
                <div className="container">
                    <div className="row">
                        <div className="section">
                            <h3 className="section-heading">Testimonials</h3>
                        </div>
                        <div className="testimonials-img-1">
                            <img src={TestmoniImg8} className="img-fluid" alt="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 col-12">
                            <Slider className="testimonials-slider-2" {...testimonialtwoslider}>
                                <div className="testimonials-block-2">
                                    <div className="client-review-2">
                                        <p className="client-review-txt">
                                            I could have asked for a better real estate agency to
                                            sell my property. They marketed it effectively, brought
                                            in qualified buyers, and negotiated a great deal They
                                            marketed it effectively, brought in qualified buyers.
                                        </p>
                                        <h5 className="client-name">James Andreson</h5>
                                        <div className="client-location">Citizen Of Canada</div>
                                    </div>
                                </div>
                                <div className="testimonials-block-2">
                                    <div className="client-review-2">
                                        <p className="client-review-txt">
                                            I could have asked for a better real estate agency to
                                            sell my property. They marketed it effectively, brought
                                            in qualified buyers, and negotiated a great deal They
                                            marketed it effectively, brought in qualified buyers.
                                        </p>
                                        <h5 className="client-name">James Andreson</h5>
                                        <div className="client-location">Citizen Of Canada</div>
                                    </div>
                                </div>
                                <div className="testimonials-block-2">
                                    <div className="client-review-2">
                                        <p className="client-review-txt">
                                            I could have asked for a better real estate agency to
                                            sell my property. They marketed it effectively, brought
                                            in qualified buyers, and negotiated a great deal They
                                            marketed it effectively, brought in qualified buyers.
                                        </p>
                                        <h5 className="client-name">James Andreson</h5>
                                        <div className="client-location">Citizen Of Canada</div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className="testimonials-img-2">
                    <img src={TestmoniImgPng} className="img-fluid" alt="" />
                </div>
            </section>
        </div>
    )
}

export default Testimonialtwo