import React from 'react'
import { Link } from "react-router-dom";
import HomeServiceImg1 from "../../assets/images/services/service-01.png";
import HomeServiceImg2 from "../../assets/images/services/service-02.png";
import HomeServiceImg3 from "../../assets/images/services/service-03.png";

function Servicedtlsone() {
    return (
        <div>
            <section id="services" className="services-main-block">
                <div className="container">
                    <div className="section">
                        <h6 className="section-sub-heading"></h6>
                        <div className="row">
                            <div className="col-lg-4">
                                <h3 className="section-heading">
                                    Hizmetlerimiz <br />                                    
                                </h3>
                            </div>
                            <div className="offset-lg-2 col-lg-4 sub-section">
                                <p>
                                Hizmetlerimiz, insanlar ve onların hayalleri hakkındadır. 
                                Bu nedenle benzersiz ihtiyaçlarınızı ve isteklerinizi dinlemeye zaman ayırıyoruz.                                
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="services-divider"></div>
                    <div className="services-block">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="services-details vertical-center">
                                    <h5 className="services-heading">
                                        Mimari Tasarım Hizmetleri
                                    </h5>
                                    <p>
                                        Mimari Tasarım Hizmetleri olarak, insanların hayallerini ve ihtiyaçlarını önemsiyoruz;
                                        bu yüzden, benzersiz isteklerinizi ve beklentilerinizi anlamak için zaman ayırıyor 
                                        ve küresel çapta hizmet sunuyoruz.
                                    </p>
                                    <Link to="/service_details" className="btn btn-link" title="">
                                        Daha fazla...
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="services-img">
                                    <div className="service-img">
                                        <div className="hover-img">
                                            <div className="hover-action" data-scale="1.15" data-image={HomeServiceImg1} >
                                                <div className="moving-image" style={{ backgroundImage: `url(${HomeServiceImg1})`, }} ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="services-divider"></div>
                    <div className="services-block">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="services-details vertical-center">
                                    <h5 className="services-heading">
                                        Lüks Daire Hizmetleri
                                    </h5>
                                    <p>
                                    Lüks Daire Hizmetleri olarak, sizlere en üst düzey konfor ve şıklığı sunmayı hedefliyoruz. İhtiyaçlarınıza ve zevkinize uygun tasarlanmış modern ve zarif dairelerimizle, yaşam kalitenizi yükseltmeyi amaçlıyoruz. Özel konumlar, üstün hizmetler ve kaliteli yaşam alanları ile hayalinizdeki lüks yaşamı gerçeğe dönüştürüyoruz.
                                    </p>
                                    <Link to="/service_details" className="btn btn-link" title="">
                                        Daha fazla...
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="services-img">
                                    <div className="service-img">
                                        <div className="hover-img">
                                            <div className="hover-action" data-scale="1.15" data-image={HomeServiceImg2} >
                                                <div className="moving-image" style={{ backgroundImage: `url(${HomeServiceImg2})`, }} ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="services-divider"></div>
                    <div className="services-block">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="services-details vertical-center">
                                    <h5 className="services-heading">
                                        Ekstra Güçlü Güvenlik Hizmetleri
                                    </h5>
                                    <p>
                                    Ekstra Güçlü Güvenlik Hizmetleri olarak, amacımız sadece konutların güvenliğini sağlamak değil, baştan sona güvenli konutlar inşa etmektir. Yüksek güvenlik standartlarına uygun tasarım ve yapım süreçlerimizle, evinizi en güvenli şekilde inşa ediyoruz. Güvenli girişler, sağlam yapı malzemeleri ve entegre güvenlik sistemleri ile sevdikleriniz için huzurlu ve güvenli yaşam alanları sunuyoruz.
                                    </p>
                                    <Link to="/service_details" className="btn btn-link" title="">
                                        Daha fazla...
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="services-img">
                                    <div className="service-img">
                                        <div className="hover-img">
                                            <div className="hover-action" data-scale="1.15" data-image={HomeServiceImg3} >
                                                <div className="moving-image" style={{ backgroundImage: `url(${HomeServiceImg3})`, }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="services-divider"></div>
                    <div className="services-btn text-center">
                        <Link to="/service" className="btn pointer-zoom btn-primary">
                            View All Services
                        </Link>
                    </div> */}
                </div>
            </section>
        </div>
    )
}

export default Servicedtlsone