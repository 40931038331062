import React from 'react';
import { Link } from "react-router-dom";

function Propertytwo() {
    // Define an array of property objects
    const properties = [
        {
            id: 1,
            icon: "flaticon-apartment",
            title: "Apartments",
            description: "We Design and services Next Generations For your office buildings",
        },
        {
            id: 2,
            icon: "flaticon-workplace",
            title: "Business Space",
            description: "We Design and services Next Generations For your office buildings",
        },
        {
            id: 3,
            icon: "flaticon-villa",
            title: "Villa House",
            description: "We Design and services Next Generations For your office buildings",
        },
    ];

    return (
        <div>
            <section id="property-find" className="find-main-block">
                <div className="container">
                    <div className="row">
                        {properties.map(property => (
                            <div className="col-lg-4" key={property.id}>
                                <div className="find-block">
                                    <i className={property.icon + " find-icon"}></i>
                                    <h5 className="find-heading mb_30">{property.title}</h5>
                                    <p className="find-txt mb_30">{property.description}</p>
                                    <Link
                                        to="/service_details"
                                        className="btn pointer-zoom btn-outline-primary"
                                        title=""
                                    >
                                        View More <i className="flaticon-right-arrow"></i>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Propertytwo;
