import React from 'react'
import HeaderTwo from '../HeaderTwo/Main'
import SliderTwo from '../MenhomeTwo/SliderTwo'
import Propertytwo from '../MenhomeTwo/Propertytwo'
import ServiceTwo from '../MenhomeTwo/ServiceTwo'
import AbtTwo from '../MenhomeTwo/AbtTwo'
import Facts from '../MenhomeTwo/Facts'
import Propertiesblock from '../MenhomeTwo/Propertiesblock'
import Latestproject from '../MenhomeTwo/Latestproject'
import Testimonialtwo from '../MenhomeTwo/Testimonialtwo'
import Partners from '../Menhomeone/Partners'
import Videotwo from '../MenhomeTwo/Videotwo'
import Teamtwo from '../MenhomeTwo/Teamtwo'
import Contactus from '../MenhomeTwo/Contactus'
import Blogtwo from '../MenhomeTwo/Blogtwo'
import NewslatterTwo from '../MenhomeTwo/NewslatterTwo'
import FooterTwo from '../FooterTwo/Main'

function Main() {
  return (
    <div>
      <HeaderTwo />
      <SliderTwo />
      <Propertytwo />
      <ServiceTwo />
      <AbtTwo />
      <Facts />
      <Propertiesblock />
      <Latestproject />
      <Testimonialtwo />
      <Partners />
      <Videotwo />
      <Teamtwo />
      <Contactus />
      <Blogtwo />
      <NewslatterTwo />
      <FooterTwo />
    </div>
  )
}

export default Main
