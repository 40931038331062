import React from "react";
import HeaderOne from '../HeaderOne/Main'
import Breadcrumb from "../Breadcrumb/Main";
import Serviceblock from "../Menhomefour/Serviceblock";
import PropertiesThree from "../MenhomeThree/PropertiesThree";
import Newsletter from "../Menhomeone/Newsletter";
import FooterOne from "../FooterOne/Main";


function Main() {
  return (
    <div>
      <HeaderOne />
      <Breadcrumb title="Services Page" subtitle="Home"/>
      <Serviceblock />
      <PropertiesThree />
      <Newsletter />
      <FooterOne />
    </div>
  );
}

export default Main;
