import React from "react";
import HeaderOne from "../HeaderOne/Main";
import Breadcrumb from "../Breadcrumb/Main";
import Blog from "../Menblog/Blog";
import Newsletter from '../Menhomeone/Newsletter'
import FooterOne from "../FooterOne/Main";

function Main() {
  return (
    <div>
      <HeaderOne />
      <Breadcrumb title="Blog Single" subtitle="Home" />
      <Blog />
      <Newsletter />
      <FooterOne />
    </div>
  );
}

export default Main;
