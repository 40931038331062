import React from "react";
import HeaderOne from "../HeaderOne/Main";
import Breadcrumb from "../Breadcrumb/Main";
import BlogSection from "../Menblogdetails/Section";
import Newsletter from "../Menhomeone/Newsletter";
import FooterOne from "../FooterOne/Main";

function Main() {
  return (
    <div>
      <HeaderOne />
      <Breadcrumb title="Blog Details" subtitle="Home" />
      <BlogSection />
      <Newsletter />
      <FooterOne />
    </div>
  );
}

export default Main;
