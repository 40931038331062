import React from 'react';
import { Link } from "react-router-dom";
import ServiceBgImg1 from "../../assets/images/bg/services-bg.jpg";

function ServiceThree() {
    const services = [
        {
            number: "01",
            icon: "flaticon-land",
            title: "Land Property Sell",
            description: "That's why we take the time to listen to your unique needs and aspirations",
        },
        {
            number: "02",
            icon: "flaticon-blueprint",
            title: "Architecture Service",
            description: "That's why we take the time to listen to your unique needs and aspirations",
        },
        {
            number: "03",
            icon: "flaticon-payment",
            title: "Payment Security",
            description: "That's why we take the time to listen to your unique needs and aspirations",
        },
        {
            number: "04",
            icon: "flaticon-offer",
            title: "Security Services",
            description: "That's why we take the time to listen to your unique needs and aspirations",
        },
        {
            number: "05",
            icon: "flaticon-construction",
            title: "Construction Service",
            description: "That's why we take the time to listen to your unique needs and aspirations",
        },
        {
            number: "06",
            icon: "flaticon-service",
            title: "Management",
            description: "That's why we take the time to listen to your unique needs and aspirations",
        },
        {
            number: "07",
            icon: "flaticon-company",
            title: "Apartment Services",
            description: "That's why we take the time to listen to your unique needs and aspirations",
        },
        {
            number: "08",
            icon: "flaticon-house",
            title: "Home Remodeling",
            description: "That's why we take the time to listen to your unique needs and aspirations",
        },
    ];

    return (
        <div>
            <section
                id="services"
                className="services-main-block-3 parallax"
                style={{ backgroundImage: `url(${ServiceBgImg1})` }}
            >
                <div className="container">
                    <div className="section svg-border-img">
                        <h6 className="section-sub-heading-2">Services</h6>
                        <div className="row">
                            <div className="col-lg-5">
                                <h3 className="section-heading mb_60">Our Latest Services</h3>
                            </div>
                            <div className="offset-lg-2 col-lg-5">
                                <p>
                                    Transactions are not just about properties they are about
                                    people and their dreams That's why we take the time to listen
                                    to your unique needs and aspirations
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {services.map((service, index) => (
                            <div key={index} className="col-lg-3">
                                <div className="services-block-3">
                                    <div className="services-no">
                                        <span>{service.number}</span>
                                    </div>
                                    <div className="services-icon">
                                        <i className={service.icon}></i>
                                    </div>
                                    <div className="services-details">
                                        <h5 className="services-heading">
                                            <Link to="/service_details" title="">
                                                {service.title}
                                            </Link>
                                        </h5>
                                        <p>{service.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ServiceThree;
