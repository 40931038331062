import React from 'react';
import { Link } from 'react-router-dom';
import PropertiesImg4 from '../../assets/images/properties/properties-04.jpg';
import PropertiesImg5 from '../../assets/images/properties/properties-05.jpg';
import PropertiesImg6 from '../../assets/images/properties/properties-06.jpg';
import PropertiesImg7 from '../../assets/images/properties/properties-07.jpg';

function PropertiesThree() {
    const properties = [
        {
            img: PropertiesImg4,
            name: 'Remon Luxury Apartments',
            bed: '4 Bed Room',
            bath: '3 Baths',
            sqr: '1300 sqr',
            price: '$1,10,809'
        },
        {
            img: PropertiesImg5,
            name: 'Remonda Luxury Apartments',
            bed: '4 Bed Room',
            bath: '3 Baths',
            sqr: '1300 sqr',
            price: '$1,10,809'
        },
        {
            img: PropertiesImg6,
            name: 'Banglo Luxury House',
            bed: '4 Bed Room',
            bath: '3 Baths',
            sqr: '1300 sqr',
            price: '$1,10,809'
        },
        {
            img: PropertiesImg7,
            name: "Mukta's Villa Apartment",
            bed: '4 Bed Room',
            bath: '3 Baths',
            sqr: '1300 sqr',
            price: '$1,10,809'
        }
    ];

    return (
        <div>
            <section id="properties" className="properties-main-block-3">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section text-center svg-border-img">
                                <h6 className="section-sub-heading">Properties</h6>
                                <h3 className="section-heading mb_60">
                                    explore Our Latest & Featured
                                    <br />
                                    Properties
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {properties.map((property, index) => (
                            <div className="col-lg-6" key={index}>
                                <div className="properties-block-3">
                                    <div className="row">
                                        <div className="col-lg-5 pad-r-0">
                                            <div className="properties-img">
                                                <div className="sale-location">
                                                    <span className="bage sale-bd">For Sale</span>
                                                    <span className="bage location-bd">
                                                        <i className="flaticon-location"></i>Canada
                                                    </span>
                                                </div>
                                                <Link to="#" title="">
                                                    <img src={property.img} className="img-fluid" alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 pad-l-0">
                                            <div className="properties-dtls-3">
                                                <h5 className="properties-heading">
                                                    <Link to="#" title="">
                                                        {property.name}
                                                    </Link>
                                                </h5>
                                                <ul className="properties-features">
                                                    <li>
                                                        <i className="flaticon-bed"></i> {property.bed}
                                                    </li>
                                                    <li>
                                                        <i className="flaticon-bathtub"></i> {property.bath}
                                                    </li>
                                                    <li>
                                                        <i className="flaticon-network"></i> {property.sqr}
                                                    </li>
                                                </ul>
                                                <div className="properties-price">{property.price}</div>
                                                <Link
                                                    to="#"
                                                    className="btn pointer-zoom btn-outline-primary"
                                                    title=""
                                                >
                                                    More Details
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default PropertiesThree;
