import React from 'react';
import CountUp from 'react-countup';

function FactsThree() {
    const facts = [
        { count: 150, duration: 2, text: 'Happy Customers in 2024' },
        { count: 991, duration: 3, text: '100% Complete Project' },
        { count: 150, duration: 2.5, text: 'Our Dedicated Team Members' }
    ];

    return (
        <div>
            <section id="facts" className="facts-main-block-2">
                <div className="container">
                    <div className="row">
                        {facts.map((fact, index) => (
                            <div key={index} className={`col-lg-4 ${index === 0 ? 'border-right' : (index === 2 ? 'border-left' : '')}`}>
                                <div className="fats-block-2 text-center">
                                    <h2 className="count">
                                        <CountUp start={0} end={fact.count} duration={fact.duration} separator="," />
                                        <span className="number">+</span>
                                    </h2>
                                    <div className="facts-text">{fact.text}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="facts-divider"></div>
                </div>
            </section>
        </div>
    );
}

export default FactsThree;
