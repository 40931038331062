import React from 'react';
import Slider from 'react-slick';
import clientImg2 from '../../assets/images/testimonials/client-02.png';
import clientImg3 from '../../assets/images/testimonials/client-03.png';
import clientImg4 from '../../assets/images/testimonials/client-04.png';
import clientImg5 from '../../assets/images/testimonials/client-05.png';
import clientImg6 from '../../assets/images/testimonials/client-06.png';
import clientImg8 from '../../assets/images/testimonials/client-08.png';
import clientImg9 from '../../assets/images/testimonials/client-09.png';
import clientImg10 from '../../assets/images/testimonials/client-10.png';

const testimonials = [
    "“I have asked for a better real estate agency to sell my property. brought in buyers, negotiated a great deal real estate agency to sell this Services is Amazing.”",
    "“I have asked for a better real estate agency to sell my property. brought in buyers, negotiated a great deal real estate agency to sell this Services is Amazing.”",
    "“I have asked for a better real estate agency to sell my property. brought in buyers, negotiated a great deal real estate agency to sell this Services is Amazing.”",
    "“I have asked for a better real estate agency to sell my property. brought in buyers, negotiated a great deal real estate agency to sell this Services is Amazing.”",
    "“I have asked for a better real estate agency to sell my property. brought in buyers, negotiated a great deal real estate agency to sell this Services is Amazing.”"
];

const TestThreeSlider = {
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};

const Testclientslider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: "linear",
    arrows: false,
};

function TestimonialThree() {
    return (
        <div>
            <section id="testimonials" className="testimonials-main-block-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="testimonials-img-3 ">
                                {[clientImg8, clientImg9, clientImg10].map((img, index) => (
                                    <img src={img} className={`img-fluid client-img-${index + 1}`} alt="" key={index} />
                                ))}
                                {[["Istambul", "India", "Istambul"]].map((location, index) => (
                                    location.map((loc, idx) => (
                                        <span className={`badge bd-${idx + 1}`} key={idx}>
                                            <i className="flaticon-location"></i>{loc}
                                        </span>
                                    ))
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="section svg-border-img">
                                <h6 className="section-sub-heading">Testimonials</h6>
                                <h3 className="section-heading mb_30">What Says Our Client</h3>
                            </div>
                            <Slider className="slider testimonials-slider-3-for" {...TestThreeSlider}>
                                {testimonials.map((testimonial, index) => (
                                    <div key={index}>
                                        <div className="testimonials-block-3">
                                            <div className="client-review-3">
                                                <p className="mb_30">{testimonial}</p>
                                                <div className="client-ratings">
                                                    <ul>
                                                        {[...Array(5)].map((_, idx) => (
                                                            <li key={idx}>
                                                                <i className="flaticon-star"></i>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            <Slider className="slider testimonials-slider-3-nav" {...Testclientslider}>
                                {[clientImg2, clientImg3, clientImg4, clientImg5, clientImg6].map((img, index) => (
                                    <div key={index}>
                                        <div className="client-img">
                                            <img src={img} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default TestimonialThree;
