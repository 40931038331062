import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import ProjectBgImg from '../../assets/images/bg/projects-bg.png';
import ProjectImg1 from '../../assets/images/projects/projects-01.jpg';
import ProjectImg2 from '../../assets/images/projects/projects-02.jpg';
import ProjectImg3 from '../../assets/images/projects/projects-03.jpg';
import ProjectImg4 from '../../assets/images/projects/projects-04.jpg';

const projectData = [
    {
        imgSrc: ProjectImg1,
        title: 'Modern House',
        city: 'Toronto',
    },
    {
        imgSrc: ProjectImg2,
        title: 'City Villa',
        city: 'Berlin',
    },
    {
        imgSrc: ProjectImg3,
        title: 'Heaven Home',
        city: 'NewYork',
    },
    {
        imgSrc: ProjectImg4,
        title: 'City Center',
        city: 'London',
    },
    {
        imgSrc: ProjectImg3,
        title: 'Heaven Home',
        city: 'NewYork',
    },
];

const projectslider = {
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    dots: false,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: false,
    centerPadding: '180px',
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};

function Latestproject() {
    return (
        <div>
            <section id="latest-projects" className="projects-main-block parallax" style={{ backgroundImage: `url(${ProjectBgImg})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section text-center">
                                <h6 className="section-sub-heading">Latest Project</h6>
                                <h3 className="section-heading mb_60">
                                    Explore Our Latest
                                    <br />
                                    Project
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Slider className="projects-slider" {...projectslider} slidesToShow={4} arrows={false}>
                                {projectData.map((project, index) => (
                                    <div className="projects-block" key={index}>
                                        <div className="projects-img">
                                            <Link to="#" title="">
                                                <img src={project.imgSrc} className="img-fluid" alt="" />
                                            </Link>
                                        </div>
                                        <h5 className="projects-heading">
                                            <Link to="#" title="">
                                                {project.title}
                                            </Link>
                                        </h5>
                                        <div className="projects-city">{project.city}</div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Latestproject;
