import React from 'react';
import { Link } from 'react-router-dom';
import AboutImg1 from '../../assets/images/about/about.png';

function Abtone() {
    return (
        <section id="about" className="about-main-block">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about-img">
                            <img src={AboutImg1} className="img-fluid about-img-1" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="section svg-border-img">
                            <h6 className="section-sub-heading">Hakkımızda</h6>
                            <h3 className="section-heading">
                                Deneyim ve Değer Yaratma
                            </h3>
                            <p>
                                Kiziltan Gayrimenkul Yatirim, kuruldugu 2014 yilindan itibaren gayrimenkul alaninda mükemmellige öncülük etmektedir. Deneyimli kadrosu, yüksek kaliteli ürün ve hizmetler sunma yetenegini, inovatif ve modern tasarimlari gelistirme becerileriyle bir araya getirmistir.
                            </p>
                            <h5>DEĞERLERİMİZ</h5>
                            <div className="about-features">
                                <ul>
                                    {[
                                        'İş Ortakları',
                                        'Ar-Ge Yatırımları',
                                        'Sürekli Gelişim',                                        
                                    ].map((feature, index) => (
                                        <li key={index}>
                                            <i className="flaticon-check-mark"></i> {feature}
                                        </li>
                                    ))}
                                </ul>
                                {/* <Link to="/about" className="btn pointer-zoom btn-primary">
                                    About More
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="box-1"></div>
                <div className="box-2"></div>
                <div className="blank-box-1"></div>
                <div className="circle-1"></div>
                <div className="border-box-1"></div> */}
            </div>
        </section>
    );
}

export default Abtone;
