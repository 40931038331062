import React from 'react'
import { Link } from 'react-router-dom'

function Find() {
    return (
        <div>
            <div id="find" className="find-main-block-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="find-dtls">
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <div className="find-btn">
                                            <Link to="#" className="btn pointer-zoom btn-primary">Buy</Link>
                                            <Link to="#" className="btn pointer-zoom btn-primary">Sell</Link>
                                            <Link to="#" className="btn pointer-zoom btn-primary">Rent</Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 text-center">
                                        <div className="find-dropdown">
                                            <button className="btn pointer-zoom btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="flaticon-location"></i>Locations <i className="flaticon-down-chevron arrow-icon"></i></button>
                                            <ul className="dropdown-menu">
                                                <li><Link className="dropdown-item" to="#">Action</Link></li>
                                                <li><Link className="dropdown-item" to="#">Another action</Link></li>
                                                <li><Link className="dropdown-item" to="#">Something else here</Link></li>
                                            </ul>
                                            <button className="btn pointer-zoom btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="flaticon-home"></i>Property Type <i className="flaticon-down-chevron arrow-icon"></i></button>
                                            <ul className="dropdown-menu">
                                                <li><Link className="dropdown-item" to="#">Action</Link></li>
                                                <li><Link className="dropdown-item" to="#">Another action</Link></li>
                                                <li><Link className="dropdown-item" to="#">Something else here</Link></li>
                                            </ul>
                                            <button className="btn pointer-zoom btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="flaticon-dollar-sign"></i>Price Range <i className="flaticon-down-chevron arrow-icon"></i></button>
                                            <ul className="dropdown-menu">
                                                <li><Link className="dropdown-item" to="#">Action</Link></li>
                                                <li><Link className="dropdown-item" to="#">Another action</Link></li>
                                                <li><Link className="dropdown-item" to="#">Something else here</Link></li>
                                            </ul>
                                            <Link to="#" className="btn pointer-zoom btn-primary find-search-btn">Search Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Find